import { Datagrid, List } from 'components/list';
import { Filter, TextField, FunctionField } from 'react-admin';
import React, { useState } from 'react';
import SearchInput from '../../components/list/SearchInput';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core';
import UserStatusField from './components/UserStatusField';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    '& .filter-field': {
      width: '100%',
    },
  },
  filterContainer: {
    width: '100%',
    display: 'flex',
    gap: '16px',
  },
  select: {
    width: '20%',
  },
}));

interface Field {
  value: string;
  label: string;
}

const fields: Field[] = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Tracking number',
    value: 'tracking.number',
  },
  {
    label: 'User ID',
    value: 'user.id',
  },
  {
    label: 'Billing ID',
    value: 'user.billing.id',
  },
  {
    label: 'Cognito ID',
    value: 'user.cognito.id',
  },
  {
    label: 'UPS Account',
    value: 'ups.account.id',
  },
  {
    label: 'USPS Account',
    value: 'usps.account.id',
  },
  {
    label: 'FedEx Account',
    value: 'fedex.account.id',
  },
  {
    label: 'Pick up ID',
    value: 'provider.pickup.id',
  },
];

const UsersFilter = (props) => {
  const classes = useStyles();

  const [searchField, setSearchField] = useState<string>('email');

  const handleChange = (e) => {
    setSearchField(e.target.value);
    props?.setFilters?.({});
  };

  return (
    <Box className={classes.filterContainer}>
      <Select value={searchField} onChange={handleChange} className={classes.select}>
        {fields.map((field) => (
          <MenuItem key={field.value} value={field.value}>
            {field.label}
          </MenuItem>
        ))}
      </Select>

      <Filter classes={classes} {...props}>
        <SearchInput
          label={fields.find((item) => item.value === searchField)?.label ?? 'Search'}
          source={searchField}
          alwaysOn
          onlySearch
          loading={props.loading}
          fullWidth
        />
      </Filter>
    </Box>
  );
};

const getRecordId = (record) => {
  if (record.deletedAt) {
    return <span style={{ color: '#FF0000' }}>{record.id} (deleted)</span>;
  }
  return record.id;
};

const getFraudCheckStatus = (record) => {
  return record?.fraudAllowedAt ? 'Disabled' : 'Enabled';
};

const UsersList = (props) => (
  <List filters={<UsersFilter />} pagination={null} {...props}>
    <Datagrid rowClick="show">
      <FunctionField label="Id" render={(record) => getRecordId(record)} />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="phone" />
      <FunctionField label="Fraud check" render={(record) => getFraudCheckStatus(record)} />
      <UserStatusField />
    </Datagrid>
  </List>
);

export default UsersList;
