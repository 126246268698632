import React, { useEffect, useCallback } from 'react';

import { TablePagination, Toolbar } from '@material-ui/core';
import { useTranslate, sanitizeListRestProps } from 'ra-core';

import PaginationActions from './PaginationActions';
import PaginationLimit from './PaginationLimit';

interface Props {
  loading?: boolean;
  page: number;
  perPage: number;
  rowsPerPageOptions?: number[];
  total: number;
  setPage: (value: number) => void;
  setPerPage: (value: number) => void;
}

const Pagination = ({
  loading,
  page,
  perPage,
  rowsPerPageOptions = [5, 10, 25],
  total,
  setPage,
  setPerPage,
  ...rest
}: Props) => {
  useEffect(() => {
    if (page < 1 || isNaN(page)) {
      setPage(1);
    }
  }, [page, setPage]);
  const translate = useTranslate();

  const getNbPages = () => Math.ceil(total / perPage) || 1;

  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || page > getNbPages() - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: page + 1,
          })
        );
      }
      setPage(page + 1);
    },
    [total, perPage, setPage, translate] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage]
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate]
  );

  if (total === 0) {
    return loading ? <Toolbar variant="dense" /> : <PaginationLimit />;
  }

  // @ts-ignore
  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      ActionsComponent={PaginationActions}
      component="span"
      labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={rowsPerPageOptions}
      {...sanitizeListRestProps(rest)}
    />
  );
};

export default React.memo(Pagination);
