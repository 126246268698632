import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: 'pointer',
    textTransform: 'none',
    minWidth: '110px',
    padding: theme.spacing(1),
  },
}));

const PrintReceiptField = (props) => {
  const {
    record: { id },
  } = props;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handlePrint = async (e) => {
    try {
      e.stopPropagation();
      const { data } = await dataProvider.getNest('claimReceipt', {
        id,
        headers: { Accept: 'application/pdf' },
      });

      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `claim_receipt_${id}.pdf`);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    }
  };

  return (
    <Button variant="contained" color="secondary" className={classes.button} onClick={handlePrint}>
      Print receipt
    </Button>
  );
};

export default PrintReceiptField;
