import React, { Children, cloneElement, createElement, isValidElement, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import { Labeled } from 'react-admin';
import { get } from 'lodash';
import ShowContext from './ShowContext';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),

    '&:not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
}));

const DefaultContainer = ({ children }) => {
  const classes = useStyles();

  return <Paper className={classes.card}>{children}</Paper>;
};

const ShowLayout = (props) => {
  const { children, baseSource, container = DefaultContainer, when } = props;

  const { basePath, resource, record } = useContext(ShowContext);

  if (when && !when(record)) {
    return null;
  }

  return createElement(
    container,
    {},
    Children.map(children, (field) => {
      if (!(field && isValidElement(field))) {
        return null;
      }

      const actualRecord = baseSource ? get(record, baseSource) : record;

      const fieldProps = field.props as any;

      return (
        <Box key={fieldProps.source} className={fieldProps.className}>
          {fieldProps.addLabel ? (
            <Labeled
              record={actualRecord}
              resource={resource}
              basePath={basePath}
              label={fieldProps.label}
              source={fieldProps.source}
              disabled={false}>
              {field}
            </Labeled>
          ) : typeof field.type === 'string' ? (
            field
          ) : (
            cloneElement(field as any, {
              record: actualRecord,
              resource,
              basePath,
            })
          )}
        </Box>
      );
    })
  );
};

export default ShowLayout;
