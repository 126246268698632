import React, { useState } from 'react';
import { TextField, FunctionField } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { capitalize } from 'lodash';

import { List, Datagrid } from 'components/list';
import { ActionField, DateField, PriceField } from 'components/fields';
import ActionButton from 'components/buttons/ActionButton';
import CreateCampaignDialog from './components/CreateCampaignDialog';
import DeleteCampaignActionField from './components/DeleteCampaingActionField';
import ExportCampaignActionField from './components/ExportCampaignActionField';

const CreditCodesActions = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ActionButton onClick={handleOpen} startIcon={<AddIcon />}>
        Create campaign
      </ActionButton>

      {open && <CreateCampaignDialog open={open} onCancel={handleClose} />}
    </>
  );
};

const CreditCodesList = (props) => {
  return (
    <List {...props} actions={<CreditCodesActions />} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        <TextField source="id" label="Campaing ID" />

        <TextField source="name" label="Campaing name" />

        <DateField label="Start" source="startAt" withTime />

        <DateField label="End" source="endAt" withTime />

        <TextField source="numberOfCodes" label="Number of codes" />

        <TextField source="details.redeemLimitOfCodes" label="Usage limit per code" />

        <FunctionField label="Type" render={(record) => capitalize(record.details.type)} />

        <FunctionField
          label="Amount"
          render={(record) =>
            record.details.type === 'fund' ? (
              <PriceField record={record} source="details.priceOfCodes" />
            ) : (
              `${record.details.priceOfCodes} ${record.details.priceOfCodes > 1 ? 'labels' : 'label'}`
            )
          }
        />

        <TextField source="numberOfRedeemed" label="Redeemed" />

        <ActionField>
          <ExportCampaignActionField />

          <DeleteCampaignActionField />
        </ActionField>
      </Datagrid>
    </List>
  );
};

export default CreditCodesList;
