import { GET_ONE, UPDATE } from 'react-admin';
import Auth from '@aws-amplify/auth';
import { pick, omit } from 'lodash';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

const dispatch = async (type, resource, params) => {
  switch (type) {
    case GET_ONE:
      return Auth.currentAuthenticatedUser({
        bypassCache: params.bypassCache === undefined ? true : params.bypassCache,
      }).then(({ attributes }) => ({
        data: {
          ...pick(attributes, ['given_name', 'family_name', 'email', 'phone_number', 'sub']),
          id: params.id,
        },
      }));
    case UPDATE:
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
        .then((user) => Auth.updateUserAttributes(user, omit(params.data, ['id'])))
        .then(() => ({ data: params.data }))
        .catch((err) => {
          throw new Error(err.message);
        });
    case CHANGE_PASSWORD:
      return Auth.currentAuthenticatedUser()
        .then((user) => Auth.changePassword(user, params.data.oldPassword, params.data.newPassword))
        .then(() => ({ data: {} }));
  }
};

export default dispatch;
