import React, { ReactNode } from 'react';

import Box from '@material-ui/core/Box';
import { default as MUIDialog } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core';

import { CloseOutlined } from '@material-ui/icons';
import SubmitButton from 'components/buttons/SubmitButton';

const styles = (theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      minWidth: 0,
      right: theme.spacing(2.5),
      top: theme.spacing(3.25),
      color: theme.palette.secondary.contrastText,
    },

    title: {
      padding: theme.spacing(4),
    },

    content: {
      padding: theme.spacing(0, 4),
      minWidth: theme.spacing(50),
      minHeight: theme.spacing(8),
      overflowX: 'hidden',
    },

    error: {
      padding: theme.spacing(2, 4, 0),
      color: theme.palette.error.main,
    },

    actions: {
      padding: theme.spacing(4),

      '& .MuiButton-containedSecondary': {
        backgroundColor: theme.palette.secondary.light,

        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },

    note: {
      padding: theme.spacing(0, 4, 4),
      fontSize: 14,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },

    root: {},
  });

const Dialog = ({
  classes,
  open,
  title,
  confirmText = 'Ok',
  confirmDisabled = false,
  cancelText = 'Cancel',
  note = null,
  children,
  submitting,
  error,
  onCancel,
  onConfirm,
}: {
  classes: any;
  open: boolean;
  title: ReactNode;
  confirmText?: string;
  confirmDisabled?: boolean;
  cancelText?: string;
  note?: ReactNode;
  children: ReactNode;
  submitting?: boolean;
  error?: string;
  onCancel: () => void;
  onConfirm?: () => void;
}) => (
  <MUIDialog open={open} onClose={onCancel} classes={{ paper: classes.root }}>
    <Button className={classes.closeButton} onClick={onCancel}>
      <CloseOutlined />
    </Button>
    <DialogTitle className={classes.title}>{title}</DialogTitle>
    <DialogContent className={classes.content}>{children}</DialogContent>
    {error && <Box className={classes.error}>{error}</Box>}
    <DialogActions className={classes.actions}>
      <Grid container spacing={4}>
        <Grid item xs={onConfirm ? 6 : 12}>
          <Button
            fullWidth
            variant="contained"
            color={onConfirm ? 'secondary' : 'primary'}
            size="large"
            onClick={onCancel}>
            {cancelText}
          </Button>
        </Grid>
        {onConfirm && (
          <Grid item xs={6}>
            <SubmitButton
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              label={confirmText}
              disabled={submitting || confirmDisabled}
              submitting={submitting}
              onClick={onConfirm}
            />
          </Grid>
        )}
      </Grid>
    </DialogActions>
    {note && <Box className={classes.note}>{note}</Box>}
  </MUIDialog>
);

export default withStyles(styles)(Dialog);
