import { GET_LIST, GET_MANY } from 'react-admin';

const getUserActivity = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.items.map((item) => ({
    ...item,
  })),
  total: response.json.total,
});

export default {
  [GET_LIST]: getUserActivity,
  [GET_MANY]: getUserActivity,
};
