import React from 'react';
import { ArrayField } from 'react-admin';
import { Package } from 'icons';
import { ShowLayout } from 'components/details';
import { CardTitle } from 'components/common';
import ListField from 'components/fields/ListField';
import { IteratorField, PackageField } from 'components/fields';

const PackageSection = (props) => (
  <ArrayField {...props} source="params.packageInfo">
    <ListField>
      <IteratorField>
        <PackageField />
      </IteratorField>
    </ListField>
  </ArrayField>
);

const PackageCard = () => (
  <ShowLayout showingFields={['packageInfo']}>
    <CardTitle icon={<Package />}>Package</CardTitle>
    <PackageSection />
  </ShowLayout>
);

export default PackageCard;
