import React from 'react';
import { createStyles, Grid, Typography, Divider, withStyles } from '@material-ui/core';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import { useRedirect } from 'react-admin';

import formatPrice from 'services/helpers/formatPrice';
import ActionButton from 'components/buttons/ActionButton';

const styles = (theme) =>
  createStyles({
    label: {
      lineHeight: '20px',
      color: theme.palette.text.secondary,
    },

    price: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 'bold',
    },

    button: {
      padding: 0,
      'min-width': '32px',
    },

    root: {
      marginBottom: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    divider: {
      margin: theme.spacing(2, 0, 1.5),
      '&:last-child': {
        display: 'none',
      },
    },

    arrowButton: {
      minWidth: 30,
      height: 20,
      padding: 1,
      maxWidth: 30,
      border: `1px solid #969AB3`,
    },
  });

const AdjustmentDetails = ({
  classes,
  transaction,
}: {
  classes: any;
  transaction: {
    [key: string]: any;
  };
}) => {
  const redirect = useRedirect();
  const { details, amount, description } = transaction;

  if (!amount) {
    return null;
  }

  const handleAdjustmentClick = (id) => {
    redirect('show', '/shipments', id);
  };

  return (
    <>
      <Grid className={classes.root} data-test="labeled-field">
        <Typography variant="overline">{details.trackNumber}</Typography>
        <ActionButton
          classes={{ root: classes.arrowButton }}
          onClick={() => handleAdjustmentClick(details.shipmentId)}>
          <KeyboardArrowRightOutlined />
        </ActionButton>
      </Grid>
      {amount && (
        <Grid container justify="space-between" alignItems="center" data-test="labeled-field">
          <Typography className={classes.label} variant="overline">
            adjustment amount
          </Typography>
          <Typography className={classes.price} variant="overline">
            {formatPrice(amount.amount)}
          </Typography>
        </Grid>
      )}
      <Grid container justify="space-between" alignItems="center" data-test="labeled-field">
        <Typography variant="body2">{description}</Typography>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

export default withStyles(styles)(AdjustmentDetails);
