import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import FilterButton from 'components/list/FilterButton';
import FilterChips from 'components/list/FilterChips';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginRight: theme.spacing(2),
    },
    textInput: {
      borderRadius: theme.spacing(3.5),
      backgroundColor: 'white',
      boxShadow: theme.shadows[1],

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRadius: theme.spacing(3.5),
      },

      '& .MuiOutlinedInput-input': {
        padding: '12.5px 14px',
      },
    },
    startAdornment: {
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(2),
    },
    endAdornment: {
      marginRight: theme.spacing(-1),
    },
    filterButton: {
      color: 'white',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      borderRadius: 0,
      borderBottomLeftRadius: theme.spacing(3.5),
      borderTopLeftRadius: theme.spacing(3.5),
      backgroundColor: '#C6C9E0',
      padding: theme.spacing(1.25, 2),
    },
  });

const SearchInput = ({
  input,
  label,
  classes,
  onlySearch,
  loading,
  fullWidth = false,
  onSearchKeyUp,
  showChips = true,
  ...props
}) => {
  return (
    <Box className={classes.root}>
      <TextField
        fullWidth={fullWidth}
        className={classes.textInput}
        {...input}
        variant="outlined"
        placeholder={label}
        onKeyDown={onSearchKeyUp || null}
        InputProps={{
          startAdornment: onlySearch ? null : (
            <InputAdornment position="start" className={classes.startAdornment}>
              <FilterButton className={classes.filterButton} {...props} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start" className={classes.endAdornment}>
              <IconButton disabled={!!loading}>
                {loading ? <CircularProgress size={24} /> : <SearchRoundedIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {!onlySearch && showChips && <FilterChips {...props} />}
    </Box>
  );
};

export default withStyles(styles)(SearchInput);
