import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Descending = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16 6a1 1 0 011 1v7.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a.998.998 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L15 14.586V7a1 1 0 011-1zm-9 9a1 1 0 010 2H5a1 1 0 010-2h2zm2-4a1 1 0 010 2H5a1 1 0 010-2h4zm2-4a1 1 0 010 2H5a1 1 0 110-2h6z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Descending;
