import React, { useState } from 'react';
import { useDataProvider, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Edit } from 'icons';
import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { minLength, maxLength, composeValidators } from 'components/form/validationRules';

const useStyles = makeStyles((theme) => ({
  action: {
    cursor: 'pointer',
    color: theme.palette.secondary.contrastText,
  },
}));

const AdminNotesEditor = (props) => {
  const { record, title } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (values) =>
    dataProvider
      .update('users', { id: record.id, data: { note: values.note } })
      .then(() => {
        refresh();
        setOpen(false);
      })
      .catch((err) => ({ [FORM_ERROR]: err.message }));

  return (
    <>
      <Edit className={classes.action} onClick={handleOpen} />

      {open && (
        <Form
          onSubmit={handleUpdate}
          initialValues={record}
          render={({ handleSubmit, submitting, ...renderProps }) => (
            <form onSubmit={handleSubmit}>
              <Dialog
                title={title}
                open={open}
                submitting={submitting}
                confirmText="Update"
                onConfirm={handleSubmit}
                onCancel={handleClose}>
                <Input
                  label="Note"
                  name="note"
                  validate={composeValidators(minLength(5), maxLength(200))}
                />
              </Dialog>
            </form>
          )}
        />
      )}
    </>
  );
};

export default AdminNotesEditor;
