import Auth from '@aws-amplify/auth';
import { Button, Collapse, FormLabel, Grid, Link, Typography } from '@material-ui/core';
import { SignUp as AmplifySignUp } from 'aws-amplify-react';
import { Input } from 'components/form';
import PasswordInput from 'components/form/PasswordInput';
import {
  composeValidators,
  email,
  onlyUSPhones,
  password,
  phoneNumber,
  required,
} from 'components/form/validationRules';
import AuthLayout, { useStyles } from 'components/layout/AuthLayout';
import { FORM_ERROR } from 'final-form';
import React, { useMemo, useState } from 'react';
import { Form, useField } from 'react-final-form';

const useWatchField = (name) => {
  const [touched, setTouched] = useState(false);
  const {
    meta: { valid },
    input: { value },
  } = useField(name);

  useMemo(() => {
    if (valid && value !== '') {
      setTouched(true);
    }
  }, [valid, value]);

  return { touched };
};

const SignUpForm = ({ handleSubmit, submitting, submitError, onSignIn, step, goBack }) => {
  const classes = useStyles();
  const { touched: emailValid } = useWatchField('email');
  const { touched: fnValid } = useWatchField('given_name');
  const { touched: lnValid } = useWatchField('family_name');

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Collapse in={step === 1}>
        <Input name="email" label="Email" fullWidth validate={composeValidators(required, email)} />
        <Collapse in={emailValid}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input name="given_name" label="First Name" fullWidth validate={required} />
            </Grid>
            <Grid item xs={6}>
              <Input name="family_name" label="Last Name" fullWidth validate={required} />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={fnValid && lnValid}>
          <PasswordInput
            name="password"
            label="Password"
            fullWidth
            validate={composeValidators(required, password)}
          />
        </Collapse>
      </Collapse>

      {step === 2 && (
        <Collapse in={true}>
          <Input
            name="phone_number"
            label="Phone Number"
            fullWidth
            parse={onlyUSPhones}
            validate={phoneNumber}
            inputProps={{
              helperText:
                'Postal Regulations require we verify the identify of our customers. You may skip this step for now however it is required prior to making your first shipment.',
            }}
          />
        </Collapse>
      )}

      {submitError && <FormLabel error>{submitError}</FormLabel>}
      <Button
        className={classes.signInButton}
        disabled={submitting}
        variant="contained"
        size="large"
        color="primary"
        fullWidth
        type="submit">
        SIGN UP
      </Button>
      {step === 2 && (
        <Typography align="center" className={classes.signUp}>
          <Link href="" onClick={goBack}>
            Back
          </Link>
        </Typography>
      )}
      <Typography align="center" className={classes.signUp}>
        Already have an account?{' '}
        <Link href="" onClick={onSignIn}>
          Sign in
        </Link>
      </Typography>
    </form>
  );
};

const SignUpView = ({ onSignUp, onSignIn }) => {
  const [step, setStep] = useState(1);

  const onSubmit = (values) => {
    if (step === 1) {
      setStep(2);
    } else {
      return onSignUp(values);
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    setStep(1);
  };

  return (
    <AuthLayout>
      <Typography variant="h6" align="center">
        Sign up
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        Rollo Ship Manager is currently in beta.
        <br />
        Please{' '}
        <Link href="http://support.rolloprinter.com/support/home" target="_blank">
          share any feedback
        </Link>
        .
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, submitError, values }) => {
          const formProps = { handleSubmit, submitting, submitError, onSignIn, step, goBack };
          return <SignUpForm {...formProps} />;
        }}
      />
    </AuthLayout>
  );
};

class SignUp extends AmplifySignUp {
  onSignIn = (e) => {
    e.preventDefault();
    this.changeState('signIn');
  };

  handleSignUp = ({ email, password, password_confirm, phone_number, ...rest }) => {
    if (!Auth || typeof Auth.signUp !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        phone_number: phone_number ? phone_number : '+1111',
        ...rest,
      },
    })
      .then(({ userConfirmed, user }) =>
        userConfirmed
          ? Auth.signIn(email, password).then((user) => this.changeState('signedIn', user))
          : this.changeState('confirmSignUp', user.getUsername())
      )
      .catch((err) => ({ [FORM_ERROR]: err.message ? err.message : JSON.stringify(err) }));
  };

  showComponent() {
    return <SignUpView onSignUp={this.handleSignUp} onSignIn={this.onSignIn} />;
  }
}

export default SignUp;
