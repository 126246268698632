import React, { forwardRef, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { EditFraudDetailsDialog } from './EditFraudDetailsDialog';

const EditFraudDetailsDialogAction = forwardRef((props: any, ref: any) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <MenuItem ref={ref} onClick={handleOpenDialog}>
        Edit fraud details
      </MenuItem>
      <EditFraudDetailsDialog open={openDialog} onCancel={handleCloseDialog} user={props.record} />
    </>
  );
});

export default EditFraudDetailsDialogAction;
