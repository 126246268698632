import React from 'react';
import { get, chain } from 'lodash';

const AmountsField = (props) => {
  const { record, source } = props;

  const handleAmount = (value, currency) => {
    if (currency === 'LABEL') {
      return value === 1 ? '1 label' : `${value} labels`;
    }

    const totalAmount = get(record, 'details.totalAmount');

    if (!totalAmount) {
      return value.toLocaleString('en-US', { style: 'currency', currency });
    }

    return totalAmount.toLocaleString('en-US', { style: 'currency', currency });
  };

  const amountsObject: { [key: string]: number } = get(record, source);

  const amounts = chain(amountsObject)
    .map((value, currency) => ({ value, currency }))
    .sortBy(['currency.length', 'currency'])
    .map(({ value, currency }) => handleAmount(value, currency))
    .value()
    .join(', ');

  return <span>{amounts}</span>;
};

export default AmountsField;
