import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { createStyles, withStyles } from '@material-ui/core';
import { InfoOutlined, CloseOutlined } from '@material-ui/icons';
import AdjustmentDetails from 'components/common/AdjustmentDetails';

const styles = (theme) =>
  createStyles({
    infoButton: {
      color: theme.palette.secondary.contrastText,
      marginLeft: theme.spacing(1.5),
    },

    content: {
      padding: theme.spacing(2, 2, 3),
      width: theme.spacing(40),
      maxHeight: theme.spacing(40),
    },

    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },

    title: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 12,
    },

    closeButton: {
      color: theme.palette.secondary.contrastText,
      marginRight: theme.spacing(-1),
    },
  });

const RefundDetails = ({
  classes,
  title,
  record,
}: {
  classes: any;
  title: React.ReactNode;
  record: {
    [key: string]: any;
  };
}) => {
  const { transactions } = record;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className={classes.infoButton} size="small" onClick={handleOpen}>
        <InfoOutlined />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Box className={classes.content}>
          <Box className={classes.header}>
            <Box className={classes.title}>{title}</Box>
            <IconButton className={classes.closeButton} size="small" onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
          {transactions?.map((transaction) => (
            <AdjustmentDetails key={transaction?.id} transaction={transaction} />
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default withStyles(styles)(RefundDetails);
