import React from 'react';
import { createStyles, withStyles, CircularProgress } from '@material-ui/core';
import { GET_LIST, Query } from 'react-admin';

const styles = (theme) =>
  createStyles({
    root: {
      maxWidth: theme.spacing(16),
      maxHeight: theme.spacing(6),
    },
  });

const ServiceLogo = ({ classes, resource, code }) => (
  <Query type={GET_LIST} resource={resource}>
    {({ data, loading, error }) => {
      if (loading) return <CircularProgress />;
      if (error) return <p>error</p>;

      const provider = data.find((p) => p.code === code);

      return <img className={classes.root} src={provider.logoUrl} alt={provider.fullName} />;
    }}
  </Query>
);

export default withStyles(styles)(ServiceLogo);
