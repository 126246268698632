import React from 'react';
import { Show } from 'components/details';
import { Grid } from '@material-ui/core';

import SummaryCard from './components/SummaryCard';
import NoteCard from './components/NoteCard';
import AdditionalDocumentsCard from './components/AdditionalDocumentsCard';
import PackageCard from './components/PackageCard';
import DeliveryCard from './components/DeliveryCard';
import BuyerCard from './components/BuyerCard';
import BackActionButton from 'components/buttons/BackActionButton';

const BackButton = (props) => {
  return <BackActionButton to="show" basePath="/users" id={props?.record?.tenantId} />;
};

const ShipmentShow = (props) => (
  <Show {...props} actions={<BackButton />}>
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <SummaryCard />
      </Grid>
      <Grid item xs={6}>
        <BuyerCard />
        <NoteCard />
        <PackageCard />
        <DeliveryCard />
        <AdditionalDocumentsCard />
      </Grid>
    </Grid>
  </Show>
);

export default ShipmentShow;
