import {
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from '@material-ui/core';
import { Checkbox } from 'components/form';
import { Columns } from 'icons';
import React, { useMemo } from 'react';
import { CRUD_GET_ONE, CRUD_UPDATE, useGetOne, useUpdate, useVersion } from 'react-admin';
import { Form, FormSpy, useForm } from 'react-final-form';
import { get, set } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(-1),
      color: theme.palette.secondary.contrastText,
    },

    paper: {
      padding: theme.spacing(2),
      maxWidth: 200,
      '& .MuiFormControl-root': {
        margin: 0,
      },
    },

    header: {
      marginBottom: theme.spacing(2),
    },
  })
);

const ColumnsConfigForm = ({ handleSubmit, fields }) => {
  const { submit } = useForm();

  return (
    <form onSubmit={handleSubmit}>
      <FormSpy subscription={{ values: true }} onChange={() => submit()} />
      {fields.map(({ props: { source, label } }) =>
        source && label ? <Checkbox key={source} name={source} label={label} /> : null
      )}
    </form>
  );
};

export const useColumnConfig = (resource) => {
  const version = useVersion();
  return useGetOne('tableSettings', resource, { version, action: CRUD_GET_ONE });
};

const ColumnsConfigButton = ({ resource: id, fields }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { data: record, loading } = useColumnConfig(id);
  const [update] = useUpdate('tableSettings', id, {}, record);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'table-columns-config-popover' : undefined;
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleSubmit = (values) =>
    update({ payload: { data: { fields: values } } }, { action: CRUD_UPDATE });

  const initialValues = useMemo(
    () =>
      fields.reduce(
        (acc, { props }) => set(acc, props.source, get(record, `fields.${props.source}`, true)),
        {}
      ),
    [fields, record]
  );

  return (
    <>
      <IconButton
        className={classes.button}
        size="small"
        onClick={handleClick}
        aria-describedby={popoverId}
        aria-label="Table columns configuration menu"
        aria-controls={popoverId}
        aria-haspopup="true">
        <Columns />
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Grid container justify="space-between" alignItems="center" className={classes.header}>
          <Typography variant="subtitle2">SHOW COLUMNS</Typography>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            render={(props) => <ColumnsConfigForm {...props} fields={fields} />}
          />
        )}
      </Popover>
    </>
  );
};

export default ColumnsConfigButton;
