import { Chip, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#24C746',
    backgroundColor: '#DFFFE6',
  },
}));

const NotificationStatusField = () => {
  const classes = useStyles();
  return <Chip className={classNames(classes.chip)} label="Sent" />;
};

export default NotificationStatusField;
