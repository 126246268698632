import React, { Children, cloneElement, useState } from 'react';
import Dialog from './Dialog';

const Confirmation = ({ title, content, children }) => {
  const child = Children.only(children);
  const { props: childProps } = child;

  const [open, setOpen] = useState(false);

  const handleClickAction = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    setOpen(false);
    childProps.onClick();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {cloneElement(child, {
        ...childProps,
        onClick: handleClickAction,
      })}
      <Dialog open={open} title={title} onConfirm={handleConfirm} onCancel={handleCancel}>
        {content}
      </Dialog>
    </>
  );
};

export default Confirmation;
