import React, { useState, useEffect, useCallback } from 'react';
import { TextField, /*ReferenceField,*/ FunctionField } from 'react-admin';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { default as TextInput } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import { Datagrid, List } from 'components/list';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    '& .filter-field': {
      width: '100%',
    },
  },
  textInput: {
    borderRadius: theme.spacing(3.5),
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: theme.spacing(3.5),
    },

    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
    },
  },
  endAdornment: {
    marginRight: theme.spacing(-1),
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const isMacAddress = (value: string): boolean =>
  /^([0-9a-f]{1,2}[.:-]){5}([0-9a-f]{1,2})$/i.test(value);

const DevicesFilter = (props) => {
  const classes = useStyles();
  const [source, setSource] = useState('macAddress');
  const [value, setValue] = useState(null);

  const onChange = useCallback((e) => {
    const value = e.target.value;
    setSource(isMacAddress(value) ? 'macAddress' : 'firmwareVersion');
    setValue(value);
  }, []);

  useEffect(() => {
    props.setFilters({ [source]: value });
  }, [source, value]); //eslint-disable-line

  return (
    <TextInput
      fullWidth
      className={classes.textInput}
      onChange={onChange}
      variant="outlined"
      placeholder="Mac address or firmware version"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" className={classes.endAdornment}>
            <IconButton>
              {props?.loading ? <CircularProgress size={24} /> : <SearchRoundedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const DevicesList = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <List filters={<DevicesFilter />} bulkActionButtons={false} pagination={false} {...props}>
      <Datagrid>
        <TextField label="Device Id" source="deviceId" variant="body1" sortable={false} />
        <TextField
          label="Firmware Version"
          source="firmwareVersion"
          variant="body1"
          sortable={false}
        />
        <TextField label="Mac Address" source="macAddress" variant="body1" sortable={false} />
        <FunctionField
          label="User ID"
          render={(record) => {
            return (
              <Box
                onClick={() => {
                  if (record?.userId) history.push(`users/${record?.userId}/show`);
                }}
                className={classes.clickable}>
                {record?.userId}
              </Box>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default DevicesList;
