import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { TextField } from 'react-admin';

import { Filter, Datagrid, List } from 'components/list';
import FilterDateInput from 'components/inputs/FilterDateInput';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';
import { reasons, statuses, providers } from './constants';
import { ActionField, DateField } from 'components/fields';
import ProviderWithTrackingField from './components/ProviderWithTrackingField';
import UserField from './components/UserField';
import ChangeStatusAction from './components/ChangeStatusAction';
import ClaimStatusField from './components/ClaimStatusField';
import PrintReceiptField from './components/PrintReceiptField';

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
  },
  form: {
    width: '50%',
    '& .filter-field': {
      width: '50%',
    },
  },
}));

const ClaimsFilter = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.filter}>
      <Typography className={classes.title}>Claims</Typography>

      <Filter withSearch {...props} searchKey="search" classes={classes}>
        <FilterDateInput icon={AccessTimeIcon} label="Date" source="date" allowEmpty />

        <ChipsArrayInput
          icon={LocalShippingOutlinedIcon}
          choices={providers}
          optionValue="code"
          optionLabel="label"
          source="carrier"
          label="Shipping"
        />

        <ChipsArrayInput
          icon={CheckCircleOutlineIcon}
          choices={reasons}
          optionValue="code"
          optionLabel="label"
          source="claimType"
          label="Reason of claim"
        />

        <ChipsArrayInput
          icon={CheckCircleOutlineIcon}
          choices={statuses}
          optionValue="code"
          optionLabel="label"
          source="status"
          label="Status"
        />
      </Filter>
    </div>
  );
};

const ClaimsList = (props) => {
  return (
    <List {...props} filters={<ClaimsFilter />}>
      <Datagrid>
        <ProviderWithTrackingField
          imageSource="carrier.logoUrl"
          source="service"
          trackingNumberSource="trackingNumber"
          trackingLinkSource="trackingLink"
          label="Service"
        />

        <UserField labelSource="userName" idSource="userId" label="User" />

        <TextField source="email" label="Email" />

        <TextField source="reason" label="Reason" />

        <DateField source="createdAt" label="Submitted" withTime />

        <DateField source="updatedAt" label="Last updated" withTime />

        <ClaimStatusField source="status" />

        <PrintReceiptField />

        <ActionField>
          <ChangeStatusAction />
        </ActionField>
      </Datagrid>
    </List>
  );
};

export default ClaimsList;
