import React from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import formatPrice from 'services/helpers/formatPrice';
import { sanitizeRestProps } from 'services/helpers/sanitize';

const PriceField = ({ record = {}, source = 'total', ...rest }) => {
  const price = get(record, source);

  return (
    <Typography variant="body1" {...sanitizeRestProps(rest)}>
      {formatPrice(price)}
    </Typography>
  );
};

export default PriceField;
