import { GET_LIST, GET_MANY } from 'react-admin';

const getDevices = ({ response, url, options, httpClient, params, apiUrl }) => {
  const data = response.json;

  return {
    ...response,
    json: data.devices.map((device) => ({
      ...device,
      id: device.deviceId,
    })),
  };
};

export default {
  [GET_LIST]: getDevices,
  [GET_MANY]: getDevices,
};
