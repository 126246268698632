import React, { memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useDataProvider, useRefresh } from 'react-admin';

import { Dialog, Divider } from 'components/common';
import UserInfo from './UserInfo';
import { LabeledValueField } from 'components/fields';
import { getCurrency } from 'pages/users/helpers/balance';
import { required } from 'components/form/validationRules';
import { Input } from 'components/form';

const useRefundStyles = makeStyles((theme) => ({
  calcRow: {
    paddingTop: theme.spacing(2),
  },

  calcTotal: {
    fontWeight: 'bold',
    fontSize: 20,
  },
}));

const RefundDialog = ({ currentTransaction, onCancel, user, balance, loadData }) => {
  const classes = useRefundStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { transactions } = currentTransaction;

  const currency = getCurrency('USD');

  const handleSubmitForm = (data) => {
    const { amount, description } = data;
    return dataProvider
      .create('balanceRefund', {
        id: user?.id,
        data: {
          amount: amount,
          description,
          transactionId: transactions[0]?.id,
        },
      })
      .then(({ data }) => {
        if (data) {
          onCancel();
          refresh();
          loadData();
        }
      })
      .catch((error) => ({ [FORM_ERROR]: error.message }));
  };

  const maxAmount = useMemo(() => {
    const currentBalance = balance.find((item) => item.currency === 'USD')?.balance;
    const currentAmount = transactions[0]?.amount.amount;
    return currentBalance < currentAmount ? currentBalance : currentAmount;
  }, [balance, transactions]);

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={{
        amount: maxAmount,
        description: 'Refund from Rollo Team',
      }}
      render={({ handleSubmit, submitting, submitError, values }) => {
        const calc = {
          current: transactions[0]?.amount.amount,
        };
        return (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={!!currentTransaction}
              title="Refund"
              onCancel={onCancel}
              confirmText="Refund"
              onConfirm={handleSubmit}
              submitting={submitting}
              error={submitError}
              cancelText="Back">
              <UserInfo user={user} />
              <Divider />
              <LabeledValueField
                classes={{ root: classes.calcRow }}
                label="Transaction Amount"
                record={calc}
                source="current"
                formatter={currency.format}
              />
              <Input
                name="amount"
                label="Amount to refund"
                inputProps={{
                  type: 'number',
                  inputProps: { min: 0, max: maxAmount },
                }}
                validate={required}
              />
              <Input
                name="description"
                label="Description"
                inputProps={{ type: 'text' }}
                validate={required}
              />
            </Dialog>
          </form>
        );
      }}
    />
  );
};

export default memo(RefundDialog);
