import React from 'react';
import clipboardCopy from 'clipboard-copy';
import { IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { useNotify } from 'react-admin';

const CopyTrackNumberButton = ({ trackNumber, ...props }) => {
  const notify = useNotify();

  const handleClick = (e) => {
    e.stopPropagation();

    if (!trackNumber) {
      return;
    }

    clipboardCopy(trackNumber)
      .then(() => {
        notify('Track number is copied');
      })
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  return (
    <IconButton {...props} onClick={handleClick}>
      <FileCopyOutlined />
    </IconButton>
  );
};

export { CopyTrackNumberButton };
