import React from 'react';
import { DateField as RADateField } from 'react-admin';

const DateField = ({ withTime = false, ...props }) => (
  <RADateField
    variant="body1"
    options={
      withTime
        ? {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }
        : { year: 'numeric', month: 'short', day: 'numeric' }
    }
    {...props}
  />
);

export default DateField;
