import React from 'react';
import { PersonOutlineOutlined } from '@material-ui/icons';
import { get } from 'lodash';
import { ShowLayout } from 'components/details';
import { CustomerField } from 'components/fields';
import { CardTitle } from 'components/common';

const BuyerSection = (props) => {
  const isReturn = get(props.record, 'type').toLowerCase() === 'return';
  return (
    <>
      <CustomerField
        {...props}
        data-test="customer"
        big
        source={isReturn ? 'params.from' : 'params.to'}
        addressSource={isReturn ? 'params.from.address' : 'params.to.address'}
      />
    </>
  );
};

const Title = (props) => {
  const isReturn = get(props.record, 'type').toLowerCase() === 'return';
  return (
    <CardTitle icon={<PersonOutlineOutlined />}>{isReturn ? 'Return from' : 'Buyer'}</CardTitle>
  );
};

const BuyerCard = () => (
  <ShowLayout>
    <Title />
    <BuyerSection />
  </ShowLayout>
);

export default BuyerCard;
