import React from 'react';
import { createStyles, withStyles, Button } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      height: theme.spacing(5.5),
      padding: theme.spacing(1.5, 3),
      boxShadow: theme.shadows[1],
    },
  });

const ActionButton = ({ classes, ...props }) => (
  <Button classes={classes} variant="contained" color="secondary" {...props} />
);

export default withStyles(styles)(ActionButton);
