import React, { Children, cloneElement } from 'react';
import classNames from 'classnames';
import { getListControllerProps, Pagination } from 'react-admin';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { ActionToolbar } from 'components/common';
import Actions from './Actions';

const styles = (theme) =>
  createStyles({
    root: {},

    short: {
      margin: '0 auto',
      maxWidth: theme.spacing(172),
    },

    main: {
      display: 'flex',
    },

    content: {
      marginTop: 0,
      transition: theme.transitions.create('margin-top'),
      position: 'relative',
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none',
      },
    },

    emptyContent: {
      padding: theme.spacing(2),
    },

    actions: {
      zIndex: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },

    noResults: { padding: 20 },
  });

const ListView = ({
  className,
  classes,
  children,
  filters,
  actions = <Actions />,
  pagination = <Pagination />,
  emptyMessage = <>No results found</>,
  ...props
}) => {
  const controllerProps = getListControllerProps(props);

  const { loaded, ids, total } = controllerProps;

  const isEmpty = loaded && (ids.length === 0 || total === 0);

  return (
    <Box className={classNames(className, classes.root, props.short && classes.short)}>
      {(filters || actions) && (
        <ActionToolbar
          left={
            filters &&
            React.cloneElement(Children.only(filters), {
              ...controllerProps,
              ...filters.props,
            })
          }>
          {Children.map(actions, (child) =>
            React.cloneElement(child, {
              ...controllerProps,
              ...child.props,
            })
          )}
        </ActionToolbar>
      )}
      <Box className={classes.main}>
        <Card
          className={classNames(classes.content, isEmpty && classes.emptyContent)}
          key={props.version}>
          {children &&
            cloneElement(Children.only(children), {
              ...controllerProps,
            })}
          {isEmpty ? emptyMessage : pagination && cloneElement(pagination, controllerProps)}
        </Card>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ListView);
