import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Checkmark = (props) => (
  <SvgIcon {...props}>
    <path
      d="M11.4922 15.7812C11.0625 16.2109 10.3984 16.2109 9.96875 15.7812L7.46875 13.2812C7.03906 12.8516 7.03906 12.1875 7.46875 11.7578C7.89844 11.3281 8.5625 11.3281 8.99219 11.7578L10.75 13.4766L14.9688 9.25781C15.3984 8.82812 16.0625 8.82812 16.4922 9.25781C16.9219 9.6875 16.9219 10.3516 16.4922 10.7812L11.4922 15.7812ZM22 12.5C22 18.0469 17.5078 22.5 12 22.5C6.45312 22.5 2 18.0469 2 12.5C2 6.99219 6.45312 2.5 12 2.5C17.5078 2.5 22 6.99219 22 12.5ZM12 4.375C7.50781 4.375 3.875 8.04688 3.875 12.5C3.875 16.9922 7.50781 20.625 12 20.625C16.4531 20.625 20.125 16.9922 20.125 12.5C20.125 8.04688 16.4531 4.375 12 4.375Z"
      fill="#969AB3"
    />
  </SvgIcon>
);

export default Checkmark;
