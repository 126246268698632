import React, { useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Button, makeStyles } from '@material-ui/core';

import { UserDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
  },
}));

interface Props {
  user: UserDetails;
}

const ResetPasswordButton = ({ user }: Props) => {
  const { id: rolloId, cognitoUserId: cognitoId } = user;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    try {
      setLoading(true);

      await dataProvider.post('resetUserPassword', {
        data: {
          rolloId,
          cognitoId,
        },
      });

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button variant="outlined" onClick={handleClick} disabled={loading} className={classes.button}>
      Password reset
    </Button>
  );
};

export default ResetPasswordButton;
