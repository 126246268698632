import React, { useState } from 'react';
import { makeStyles, Popover, Button } from '@material-ui/core';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import moment from 'moment';
import { useField } from 'react-final-form';
import MomentUtils from '@date-io/moment';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(2),
  },
  popover: {
    '& .MuiToolbar-root': {
      display: 'none',
    },
  },
  dateButton: {
    border: 'none',
    textTransform: 'none',
    padding: '2px 2px 2px 8px',
    '&:hover': {
      border: 'none',
    },
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const TimeInputField = (props) => {
  const { label, name, validate } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField(name, { validate });

  const handleChange = (newValue) => {
    onChange(newValue ? newValue : null);
  };

  return (
    <>
      <Popover
        className={classes.popover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            className={classes.input}
            autoOk
            ampm
            value={value ? moment(value, 'HH:mm:ss') : null}
            onChange={handleChange}
            open={!!anchorEl}
            onClose={handleClose}
          />
        </MuiPickersUtilsProvider>
      </Popover>

      <Button
        onClick={handleOpen}
        color="primary"
        variant="outlined"
        className={classNames(classes.dateButton, touched && !!error && classes.error)}>
        {value ? value.format('LT') : label}
        <ArrowDropDownOutlinedIcon />
      </Button>
    </>
  );
};

export default TimeInputField;
