import React, { useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Form } from 'react-final-form';

import { Button, makeStyles } from '@material-ui/core';
import { Dialog } from 'components/common';
import { Input, Checkbox } from 'components/form';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
  },
}));

const GlobalResetPasswordButton = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleMassResetClick = async ({ token, permitsPerSecond, redefine }) => {
    try {
      setLoading(true);

      await dataProvider.post('massResetUserPassword', {
        data: {
          token,
          permitsPerSecond,
          redefine,
        },
      });

      handleCloseDialog();

      refresh();
    } catch (error: any) {
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpenDialog}
        disabled={loading}
        className={classes.button}>
        Global Password reset
      </Button>
      {openDialog && (
        <Form
          onSubmit={handleMassResetClick}
          initialValues={{ token: '', permitsPerSecond: '', redefine: false }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Dialog
                  open={openDialog}
                  onCancel={handleCloseDialog}
                  title="Global password reset"
                  onConfirm={handleSubmit}
                  confirmText="Continue"
                  submitting={submitting || loading}>
                  <Input name="token" label="Token" />

                  <Input name="permitsPerSecond" label="Permits per second" />

                  <Checkbox name="redefine" label="Redefine" />
                </Dialog>
              </form>
            );
          }}
        />
      )}
    </>
  );
};

export default GlobalResetPasswordButton;
