import React from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { useRedirect } from 'react-admin';
import { CopyTrackNumberButton } from 'pages/shipments/components/CopyTrackNumber';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}));

const LabeledUrlField = ({
  record = {},
  source,
  labelSource = source,
  target = '_blank',
  enableCopy = false,
  ...rest
}) => {
  const redirect = useRedirect();
  const classes = useStyles();
  const trackLink = get(record, source);
  const trackNumber = get(record, labelSource);

  const isUSPS = record?.details?.description === 'USPS Adjustment';
  const isUPS = record?.details?.description === 'UPS Adjustment';

  const handleAdjustmentRedirect = (id) => {
    redirect('show', '/shipments', id);
  };

  if (isUPS || isUSPS) {
    return record?.transactions?.map((transaction) => (
      <Link
        style={{ display: 'flex' }}
        key={transaction?.id}
        underline="always"
        onClick={() => handleAdjustmentRedirect(transaction?.details?.shipmentId)}>
        {transaction?.details?.trackNumber}
      </Link>
    ));
  }

  if (!trackNumber) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Link className={rest.className} href={trackLink} target={target} underline="always">
        {rest.children || trackNumber}
      </Link>
      {enableCopy && <CopyTrackNumberButton trackNumber={trackNumber} />}
    </div>
  );
};

export default LabeledUrlField;
