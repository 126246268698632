import React from 'react';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const InfoField = (props) => {
  const {
    record: { addressLine1, addressLine2, addressLine3, city, stateCode, countryCode, postalCode },
  } = props;

  const fullAddress = [
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    postalCode,
    stateCode,
    countryCode,
  ]
    .filter((item) => item)
    .join(', ');

  return (
    <Tooltip title={fullAddress} placement={'bottom-start'} enterDelay={300}>
      <InfoOutlined fontSize="small" />
    </Tooltip>
  );
};

export default InfoField;
