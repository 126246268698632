import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import moment from 'moment';

const DebtorsDateField = (props) => {
  const { record, source } = props;
  const date = get(record, source);
  if (!date) return <Box>-</Box>;
  return <Typography variant="body2">{moment(date).format('D MMM')}</Typography>;
};

export default DebtorsDateField;
