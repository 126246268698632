import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Package = (props) => (
  <SvgIcon {...props}>
    <path
      d="M11.485 1.142a1 1 0 011.03 0l9.983 5.99a.994.994 0 01.21.16l.03.032a1.01 1.01 0 01.262.694V16a1 1 0 01-.486.857l-10 6-.016.01-.037.02-.012.006.047-.026-.066.035-.048.022a1.077 1.077 0 01-.109.038l-.05.013a.864.864 0 01-.075.014l-.026.004a.845.845 0 01-.072.006L12 23a1.022 1.022 0 01-.121-.007l-.028-.004a.887.887 0 01-.178-.044l-.056-.021a.912.912 0 01-.041-.019l-.038-.018-.036-.02-.017-.01-10-6A1 1 0 011 16V7.984l.002-.052.001-.013-.002.064.005-.091.007-.053a.882.882 0 01.073-.244.988.988 0 01.255-.348l.03-.025.04-.03a.892.892 0 01.067-.045l.007-.005L5.53 4.716l1.638-.983zM21 9.766l-8 4.8v5.668l8-4.8zm-18 0v5.668l8 4.8v-5.668zm4 3.566l3 1.8v3.336l-3-1.8zm.167-7.266L3.944 8l1.193.716L12 12.834l3.223-1.934zM12 3.166L9.11 4.9l7.905 4.742c.032.02.057.046.081.072l.039.038v.001L20.057 8z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Package;
