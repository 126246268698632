import React from 'react';
import { get } from 'lodash';
import { Typography, Tooltip, makeStyles } from '@material-ui/core';
import addressToString from 'services/helpers/addressToString';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: '430px',
    padding: 10,
    borderRadius: '16px',
    lineHeight: 1.2,
    zIndex: 3,
    fontSize: '16px',
  },
  secondary: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
}));

const WarehouseField = ({
  record = {},
  source,
  basePath,
  className,
}: {
  record?;
  source: string;
  basePath?;
  className?;
  label?;
}) => {
  const classes = useStyles();
  const isReturn = get(record, 'type').toLowerCase() === 'return';

  const parsedAddress = addressToString(get(record, source), {
    withName: true,
  });

  return (
    <>
      <Typography className={classes.secondary} data-test="warehouse">
        {isReturn ? 'RETURN TO' : 'FROM'}
      </Typography>
      <Tooltip
        title={parsedAddress}
        placement={'bottom-start'}
        enterDelay={300}
        classes={{ tooltip: classes.tooltip }}>
        <Typography className={className} data-test="warehouse">
          {get(record, `${source}.warehouseName`)}
        </Typography>
      </Tooltip>
    </>
  );
};

export default WarehouseField;
