import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';
import moment from 'moment';

let intervalId;

export const ExportAccountDataActionField = forwardRef((props: any, ref: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { record } = props;

  const handleClick = async () => {
    try {
      const {
        data: { executionArn },
      } = await dataProvider.getOne('exportDebtors', { id: record.id });

      intervalId = setInterval(async () => {
        const {
          data: { status, downloadURL },
        } = await dataProvider.get('exportDebtorsStatus', {
          arn: executionArn,
        });
        if (status !== 'RUNNING') {
          clearInterval(intervalId);
          if (status === 'SUCCEEDED' && downloadURL) {
            const data: any = await fetch(downloadURL);
            const url = window.URL.createObjectURL(new Blob([await data.text()]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${record?.name || record?.email || ''}_${moment().format()}.html`
            );
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
          }
        }
      }, 5000);
    } catch (error: any) {
      notify(error.message);
    }
  };
  return (
    <MenuItem ref={ref} onClick={handleClick}>
      Export account data
    </MenuItem>
  );
});
