import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, withStyles } from '@material-ui/core';
import { Field, FieldProps } from 'react-final-form';
import { TextFieldProps } from '@material-ui/core/TextField';

interface IInputProps {
  inputProps?: TextFieldProps;
}

const styles = (theme) =>
  createStyles({
    root: {},

    input: {
      marginTop: theme.spacing(2),

      '& .MuiFormLabel-root': {
        color: theme.palette.secondary.contrastText,
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: '#E0E0E0',
      },

      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: theme.palette.primary.main,
      },
    },

    helperTextContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    characterCounter: {
      marginLeft: 'auto',
    },
  });

const Input = ({
  className,
  classes,
  name,
  disabled,
  label,
  inputProps = {},
  fullWidth = true,
  type = 'text',
  characterCount,
  formatValue = (value) => value,
  ...rest
}: FieldProps<any, any> & IInputProps) => (
  <Field
    name={name}
    render={({ input: { name, onChange, value }, meta: { touched, error } }) => {
      const isError = !!(touched && error);

      const handleChange = (event) => {
        onChange(formatValue(event.target.value));
      };

      return (
        <Box className={classNames(classes.root, className)}>
          <TextField
            className={classes.input}
            name={name}
            type={type}
            label={label}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={isError}
            fullWidth={fullWidth}
            {...inputProps}
          />
          <div className={classes.helperTextContainer}>
            {isError && <FormHelperText error>{error}</FormHelperText>}

            {!!characterCount && (
              <FormHelperText className={classes.characterCounter}>
                {value?.length}/{characterCount}
              </FormHelperText>
            )}
          </div>
        </Box>
      );
    }}
    {...rest}
  />
);

export default withStyles(styles)(Input);
