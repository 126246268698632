import React from 'react';
import classNames from 'classnames';
import { Button, createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      color: theme.palette.secondary.contrastText,
      borderColor: theme.palette.primary.light,
      verticalAlign: 'baseline',
    },
  });

const ToolButton = ({ classes, className, ...props }) => (
  <Button
    className={classNames(classes.root, className)}
    variant="outlined"
    size="small"
    {...props}
  />
);

export default withStyles(styles)(ToolButton);
