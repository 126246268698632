import React from 'react';
import { Chip, makeStyles, Typography } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
}));

const NameField = (props) => {
  const { record, nameSource, deletedSource } = props;
  const classes = useStyles();

  const name = get(record, nameSource, '');
  const deleted = get(record, deletedSource);

  return (
    <div>
      <Typography>{name}</Typography>

      {!!deleted && <Chip label="Deleted" className={classes.chip} />}
    </div>
  );
};

export default NameField;
