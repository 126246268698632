import fakeRestProvider from 'ra-data-fakerest';
import Auth from '@aws-amplify/auth';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';
import apiRest, { GET, POST } from './rest';
import localstorage from 'services/data/localstorage';
import profileProvider from './profileProvider';
import { get } from 'lodash';

const getApiUrlDevices = () => {
  return window.location?.hostname === 'admin.rollo-app.com'
    ? 'https://bdogkbbnc5.execute-api.us-east-1.amazonaws.com/prod/devices'
    : 'https://21mrmts9dl.execute-api.us-east-1.amazonaws.com/dev/devices';
};

const apiUrl = process.env.REACT_APP_DATA_PROVIDER_URL;
const apiUrlDevices = process.env.REACT_APP_DATA_DEVICES_URL ?? getApiUrlDevices();

const getBlackbox = () => {
  const { ioGetBlackbox } = window as any;

  if (typeof ioGetBlackbox !== 'function') {
    return;
  }

  const bbData = ioGetBlackbox();

  if (bbData.finished) {
    return bbData.blackbox;
  }
};

const binaryFetch = (url, options) => {
  if (options.user && options.user.authenticated && options.user.token) {
    options.headers.set('Authorization', options.user.token);
  }

  return fetch(url, options).then((response) => {
    const { status, statusText, headers } = response;

    if (status < 200 || status >= 300) {
      return response.text().then((text) => {
        let json;
        try {
          json = JSON.parse(text);
        } catch (e) {}

        return Promise.reject({
          message: json?.message || text || statusText,
          status,
          json,
        });
      });
    }

    return response.blob().then((blob) => ({
      status,
      headers,
      blob,
    }));
  });
};

export const httpClient = (url, options) =>
  Auth.currentSession().then((aws) => {
    const idToken = aws['idToken'];

    options.user = {
      authenticated: true,
      token: `Bearer ${idToken.jwtToken}`,
    };

    options.headers = new Headers({
      Accept: 'application/json',
      'X-UPS-Device-Id': getBlackbox(),
      ...options.headers,
    });

    if (options.headers.get('Accept') !== 'application/json') {
      return binaryFetch(url, options);
    }

    return fetchUtils.fetchJson(url, options);
  });

/*const localDefaultParams = {
  pagination: {
    page: 1,
    perPage: 100
  },
  sort: {
    field: 'id',
    order: 'ASC'
  }
};*/

const typeToMethodMap = {
  GET_ONE: 'getOne',
  GET_NEST: 'getNest',
  GET_LIST: 'getList',
  GET_MANY: 'getMany',
  GET_MANY_REFERENCE: 'getManyReference',
  CREATE: 'create',
  UPDATE: 'update',
  UPDATE_MANY: 'updateMany',
  DELETE: 'delete',
  DELETE_MANY: 'deleteMany',
  GET: 'get',
  POST: 'post',
};

const fakeData = require('./db.json');
const fakeRest = fakeRestProvider(fakeData);

const providers = {
  local: (type, resource, params) =>
    type === 'GET'
      ? new Promise((resolve) => {
          setTimeout(() => {
            resolve({ data: fakeData[resource] });
          }, 1000);
        })
      : fakeRest(type, resource, params),
  liveApi: apiRest(apiUrl, httpClient),
  profileProvider,
  devicesProvider: apiRest(apiUrlDevices, httpClient, true),
  localstorage: (type, resource, params) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(localstorage(type, resource, params));
      }, 60);
    }),
};

class RestAdapter {
  constructor(public switchProvider: Function) {}

  getList = (resource, params) => this.switchProvider(GET_LIST, resource, params);
  getOne = (resource, params) => this.switchProvider(GET_ONE, resource, params);
  get = (resource, params) => this.switchProvider(GET, resource, params);
  getNest = (resource, params) => this.switchProvider('GET_NEST', resource, params);
  post = (resource, params) => this.switchProvider(POST, resource, params);
  getMany = (resource, params) => this.switchProvider(GET_MANY, resource, params);
  getManyReference = (resource, params) =>
    this.switchProvider(GET_MANY_REFERENCE, resource, params);
  create = (resource, params) => this.switchProvider(CREATE, resource, params);
  update = (resource, params) => this.switchProvider(UPDATE, resource, params);
  updateMany = (resource, params) => this.switchProvider(UPDATE_MANY, resource, params);
  delete = (resource, params) => this.switchProvider(DELETE, resource, params);
  deleteMany = (resource, params) => this.switchProvider(DELETE_MANY, resource, params);
}

const dataProvider = new RestAdapter((type, resource, params) => {
  switch (resource) {
    case 'users':
      if (type === 'GET_LIST' && !Object.keys(get(params, 'filter'))?.length) {
        return Promise.resolve({ data: [], total: 0 });
      }
      return providers.liveApi(type, resource, params);
    case 'profile':
      return providers.profileProvider(type, resource, params);
    case 'tableSettings':
    case 'rowsPerPageSettings':
      return providers.localstorage(type, resource, params);
    case 'devices':
      if (
        type === 'GET_LIST' &&
        !get(params, 'filter.macAddress') &&
        !get(params, 'filter.firmwareVersion')
      ) {
        return Promise.resolve({ data: [], total: 0 });
      }
      return providers.devicesProvider(type, resource, params);
    case 'userDevices':
      if (type === 'GET_LIST' && !get(params, 'filter.userId')) {
        return Promise.resolve({ data: [], total: 0 });
      }
      return providers.devicesProvider(type, resource, params);
    default:
      return providers.liveApi(type, resource, params);
  }
});

export default new Proxy(dataProvider, {
  get: (target: RestAdapter, property: string | number | symbol, receiver: any): any =>
    Reflect.get(target, target[property] ? property : typeToMethodMap[property], receiver),
});
