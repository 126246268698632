import React, { useState } from 'react';
import { CircularProgress, Link } from '@material-ui/core';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDataProvider, useNotify } from 'react-admin';

const StripeField = (props) => {
  const { record } = props;

  const { id } = useParams();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const receiptNumber = get(record, 'transactions[0].details.receiptDetails.receiptNumber');

  const [loading, setLoading] = useState<boolean>(false);

  if (!receiptNumber) {
    return null;
  }

  const handleRedirect = async () => {
    try {
      if (!id || !receiptNumber) {
        return;
      }

      setLoading(true);

      const { data } = await dataProvider.get('stripeReceipt', { _id: id, receiptNumber });

      window.open(data, '_blank');
    } catch (error: any) {
      notify(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Link component="button" onClick={handleRedirect}>
      View on Stripe
    </Link>
  );
};

export default StripeField;
