import React, { cloneElement } from 'react';
import { useShowController } from 'ra-core';

import DefaultActions from './ShowActions';
import ShowContext from './ShowContext';

export const Show = ({ actions, children, ...props }) => {
  const controllerProps = useShowController(props as any);

  const allProps = {
    ...props,
    ...controllerProps,
  };

  if (typeof actions === 'undefined' && props.hasEdit) {
    actions = <DefaultActions />;
  }

  if (!children) {
    return null;
  }

  return (
    <>
      {actions &&
        cloneElement(actions, {
          ...allProps,
          data: allProps.record,
          ...actions.props,
        })}
      {allProps.record && <ShowContext.Provider value={allProps}>{children}</ShowContext.Provider>}
    </>
  );
};

export default Show;
