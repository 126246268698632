import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/core';
import { get, omit } from 'lodash';
import moment from 'moment';
import { useGetMany } from 'react-admin';

const FilterChip = (props) => (
  <Grow in>
    <Chip style={{ margin: '4px 8px 4px 0' }} variant="outlined" {...props} />
  </Grow>
);

const useReferenceChipProps = (chipProps) => {
  const { filterValues, filterName, setFilters, filters } = chipProps;
  const reference = get(
    filters.find((f) => f.props.source === filterName),
    'props.reference'
  );
  const onDelete = (id) =>
    setFilters({ ...filterValues, [filterName]: filterValues[filterName].filter((e) => e !== id) });
  const { data, error, loading } = useGetMany(reference, filterValues[filterName]);

  return [data, error, loading, onDelete];
};

const MarketChip = (props) => {
  const [data, error, loading, onDelete] = useReferenceChipProps(props);
  if (error) return null;
  if (loading) return null;

  return data.map(
    (entity) =>
      entity && (
        <FilterChip
          key={entity.id}
          avatar={<Avatar alt={entity.provider.fullName} src={entity.provider.smallLogoUrl} />}
          label={entity.number}
          onDelete={() => onDelete(entity.id)}
        />
      )
  );
};

const ShippingChip = (props) => {
  const [data, error, loading, onDelete] = useReferenceChipProps(props);
  if (error) return null;
  if (loading) return null;

  return data.map(
    (entity) =>
      entity && (
        <FilterChip
          key={entity.id}
          avatar={<Avatar alt={entity.provider.fullName} src={entity.provider.logoUrl} />}
          label={entity.number}
          onDelete={() => onDelete(entity.id)}
        />
      )
  );
};

const StatusChip = (props) => {
  const [data, error, loading, onDelete] = useReferenceChipProps(props);
  if (error) return null;
  if (loading) return null;

  return data.map((entity) => (
    <FilterChip key={entity.id} label={entity.name} onDelete={() => onDelete(entity.id)} />
  ));
};

const DateChip = (props) => {
  const { filterName, filterValues, setFilters } = props;
  const onDelete = () => setFilters(omit(filterValues, [filterName]));
  const from = get(filterValues, `${filterName}.from`);
  const to = get(filterValues, `${filterName}.to`);
  const label = `${from ? moment(from).format('L') : '*'} - ${to ? moment(to).format('L') : '*'}`;

  return <FilterChip label={label} onDelete={() => onDelete()} />;
};

const ChipsSwitch = (props) => {
  const { filterName } = props;

  switch (filterName) {
    case 'shipping':
      return <ShippingChip {...props} />;
    case 'market':
      return <MarketChip {...props} />;
    case 'status':
      return <StatusChip {...props} />;
    case 'date':
      return <DateChip {...props} />;
    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2.5, 0, -0.5),
  },
}));

const FilterChips = (props) => {
  const { filterValues } = props;
  const filters = Object.keys(filterValues);
  const classes = useStyles();

  const inner = useMemo(
    () =>
      filters.map((filterName) => (
        <ChipsSwitch key={filterName} filterName={filterName} {...props} />
      )),
    [filters] //eslint-disable-line
  );

  if (!filters.length) {
    return null;
  }

  return <Box className={classes.root}>{inner}</Box>;
};

export default FilterChips;
