import React, { memo } from 'react';

import Box from '@material-ui/core/Box';

const InitialBalanceField = (props) => {
  const { record } = props;

  const balances = record?.transactions
    ?.map((transaction) => transaction?.initialBalance)
    ?.map(({ amount, currency }) =>
      currency === 'LABEL'
        ? amount === 1
          ? '1 label'
          : `${amount} labels`
        : amount.toLocaleString('en-US', { style: 'currency', currency })
    )
    .join(', ');

  return <Box>{balances}</Box>;
};

export default memo(InitialBalanceField);
