import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';

const ExportCampaignActionField = forwardRef((props: any, ref: any) => {
  const { record } = props;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleExportCampaign = async () => {
    try {
      const { data } = await dataProvider.getNest('exportCreditCodes', { id: record.id });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${record.id}.csv`);
      document.body.appendChild(link);

      link.click();
      link.parentNode?.removeChild(link);
    } catch (error: any) {
      console.log(error);
      notify(error.message);
    }
  };

  return (
    <MenuItem ref={ref} onClick={handleExportCampaign}>
      Export
    </MenuItem>
  );
});

export default ExportCampaignActionField;
