import React, { cloneElement } from 'react';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import { IconButton, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { isFunction } from 'lodash';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.contrastText,
  },
}));

const ActionField = (props) => {
  const { children } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleChildItemClick = (onClick) => (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (isFunction(onClick)) onClick(event);
  };

  return (
    <Box>
      <IconButton
        className={classes.button}
        aria-label="actions menu"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}>
        {React.Children.map(children, (child) =>
          cloneElement(child, {
            ...child.props,
            ...props,
            onClick: handleChildItemClick(child.props.onClick),
          })
        )}
      </Menu>
    </Box>
  );
};

export default ActionField;
