import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import { createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    title: {
      padding: theme.spacing(3, 0, 6),
    },

    item: {
      position: 'relative',
      boxSizing: 'border-box',
      paddingBottom: '66%',
    },

    image: {
      position: 'absolute',
      width: `calc(100% - ${theme.spacing(8)}px)`,
      height: `calc(100% - ${theme.spacing(8)}px)`,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: theme.spacing(25),
      maxHeight: theme.spacing(8),
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  });

const ImageLinkList = ({ classes, title, items }) => (
  <Box className={classes.container}>
    <Box className={classes.title}>{title}</Box>
    <Grid container spacing={4} justify="center">
      {items.map((item, i) => (
        <Grow key={item.path} in timeout={300 * i}>
          <Grid item xs={3}>
            <Link to={item.path}>
              <Paper className={classes.item}>
                <Box className={classes.image} style={{ backgroundImage: `url(${item.image})` }} />
              </Paper>
            </Link>
          </Grid>
        </Grow>
      ))}
    </Grid>
  </Box>
);

export default withStyles(styles)(ImageLinkList);
