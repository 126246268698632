import React from 'react';
import { createStyles, MenuItem, withStyles } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import Auth from '@aws-amplify/auth';
import DropdownTab from './DropdownTab';
import UserName from './UserName';

const styles = (theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
    },
  });

const UserMenu = ({ classes }) => {
  const logout = () => Auth.signOut();

  return (
    <DropdownTab label={<UserName />}>
      <MenuItem onClick={logout}>
        <ExitToAppOutlined className={classes.icon} /> Logout
      </MenuItem>
    </DropdownTab>
  );
};

export default withStyles(styles)(UserMenu);
