import ToolButton from 'components/buttons/ToolButton';
import React, { memo } from 'react';

const RefundField = (props) => {
  const { record, onRefund } = props;
  const isRefunded =
    record?.transactions &&
    record.transactions[0]?.details?.receiptDetails?.refundStatus === 'succeeded';
  const isUSD = record?.transactions && record.transactions[0]?.amount?.currency === 'USD';
  if (record.type !== 'DEPOSIT' || record?.status !== 'CONFIRMED' || isRefunded || !isUSD)
    return null;

  return <ToolButton onClick={() => onRefund(record)}>Refund</ToolButton>;
};

export default memo(RefundField);
