import React, { FunctionComponent } from 'react';
import { createStyles, withStyles, Grid, Typography } from '@material-ui/core';
import { FieldProps, InjectedFieldProps } from 'react-admin';
import PriceField from 'components/fields/PriceField';

interface PriceFieldPropsInterface {
  labelVariant?: string;
  priceVariant?: string;
}

const styles = (theme) =>
  createStyles({
    root: {},

    label: {
      lineHeight: '20px',
      color: theme.palette.text.secondary,
    },

    price: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 'bold',
    },
  });

const LabeledPriceField: FunctionComponent<
  InjectedFieldProps & FieldProps & PriceFieldPropsInterface
> = ({ classes, record, source, label, labelVariant = 'overline', priceVariant = 'body1' }) => (
  <Grid className={classes.root} container justify="space-between" alignItems="center">
    <Typography className={classes.label} variant={labelVariant}>
      {label}
    </Typography>
    <PriceField className={classes.price} {...{ record, source }} variant={priceVariant} />
  </Grid>
);

export default withStyles(styles)(LabeledPriceField);
