import React from 'react';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import { createStyles, withStyles } from '@material-ui/core';
import { useUpdate, useNotify } from 'react-admin';
import { Switch } from 'components/common';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    label: {
      fontSize: 14,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
  });

const SwitchField = (props) => {
  const { classes, resource, record, source, invert } = props;

  let value = get(record, source);

  const checked = invert ? !value : value;

  const notify = useNotify();

  const [update, { loading }] = useUpdate(resource, record.id, { [source]: !value }, record, {
    onSuccess: () => {
      notify('ra.notification.updated', 'info', { smart_count: 1 });
    },
    onFailure: (error) =>
      notify(
        typeof error === 'string' ? error : error.message || 'ra.notification.http_error',
        'warning'
      ),
  });

  return (
    <Box className={classes.root}>
      <span className={classes.label}>{checked ? 'Enabled' : 'Disabled'}</span>
      <Switch checked={checked} disabled={loading} onChange={update} />
    </Box>
  );
};

export default withStyles(styles)(SwitchField);
