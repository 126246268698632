import React from 'react';
import { createStyles, Typography, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(3, 4, 5),
    },

    copyright: {
      color: theme.palette.primary.light,
    },

    link: {
      marginLeft: theme.spacing(4),
      fontWeight: 'bold',
      color: theme.palette.secondary.contrastText,
    },
    buildNumber: {
      color: theme.palette.primary.light,
      marginLeft: theme.spacing(4),
    },
  });

const Footer = ({ classes, ...props }) => (
  <footer className={classes.root}>
    <Typography className={classes.copyright} color="textSecondary" variant="body2">
      Copyright &copy; 2019&ndash;{new Date().getFullYear()} Nelu LLC
    </Typography>
    <Typography className={classes.buildNumber} color="textSecondary" variant="body2">
      build #{process.env.REACT_APP_BUILD_NUMBER}
    </Typography>
  </footer>
);

export default withStyles(styles)(Footer);
