import Auth from '@aws-amplify/auth';
import { Button, Collapse, FormLabel, Link, Typography } from '@material-ui/core';
import { ForgotPassword as AmplifyForgotPassword } from 'aws-amplify-react';
import { Input } from 'components/form';
import PasswordInput from 'components/form/PasswordInput';
import { composeValidators, password, required } from 'components/form/validationRules';
import AuthLayout, { useStyles } from 'components/layout/AuthLayout';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import { Form } from 'react-final-form';

const SendView = ({ onSubmit, onSignIn }) => {
  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Typography variant="body1">
            Enter your Email below and we will send a message to reset your password.
          </Typography>
          <Input name="email" label="Email" fullWidth validate={required} />
          {submitError && <FormLabel error>{submitError}</FormLabel>}
          <Button
            className={classes.signInButton}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            type="submit"
            disabled={submitting}>
            SEND CODE
          </Button>
        </form>
      )}
    />
  );
};

const SubmitView = ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Input name="code" label="Code" fullWidth validate={required} />
          <PasswordInput
            name="password"
            label="New Password"
            fullWidth
            validate={composeValidators(required, password)}
          />
          {submitError && <FormLabel error>{submitError}</FormLabel>}
          <Button
            className={classes.signInButton}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            type="submit"
            disabled={submitting}>
            CHANGE PASSWORD
          </Button>
        </form>
      )}
    />
  );
};

const ForgotPasswordView = ({ onSignIn, sendCode, onSubmitPassword }) => {
  const [delivery, setDelivery] = useState<any>(null);
  const [username, setUsername] = useState('');
  const classes = useStyles();

  const onSendCode = ({ email }) =>
    sendCode(email)
      .then((data) => setDelivery(data.CodeDeliveryDetails))
      .then(() => setUsername(email))
      .catch((err) => ({ [FORM_ERROR]: err.message ? err.message : JSON.stringify(err) }));

  const onSubmit = ({ code, password }) =>
    onSubmitPassword({ username, code, password }).catch((err) => ({
      [FORM_ERROR]: err.message ? err.message : JSON.stringify(err),
    }));

  return (
    <AuthLayout>
      <Typography variant="h6" align="center" gutterBottom>
        Forgot Password?
      </Typography>
      <Collapse in={!delivery}>
        <SendView onSubmit={onSendCode} onSignIn={onSignIn} />
      </Collapse>
      <Collapse in={!!delivery}>
        <SubmitView onSubmit={onSubmit} />
      </Collapse>
      <Typography align="center" className={classes.signUp}>
        <Link href="" onClick={onSignIn}>
          Back to Sign In
        </Link>
      </Typography>
    </AuthLayout>
  );
};

class ForgotPassword extends AmplifyForgotPassword {
  onSignIn = (e) => {
    e.preventDefault();
    this.changeState('signIn');
  };

  sendCode = (email) => {
    if (!Auth || typeof Auth.forgotPassword !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    return Auth.forgotPassword(email);
  };

  submitNewPassword = ({ username, code, password }) => {
    if (!Auth || typeof Auth.forgotPasswordSubmit !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    return Auth.forgotPasswordSubmit(username, code, password).then(() => {
      this.changeState('signIn');
    });
  };

  showComponent(theme) {
    return (
      <ForgotPasswordView
        onSignIn={this.onSignIn}
        sendCode={this.sendCode}
        onSubmitPassword={this.submitNewPassword}
      />
    );
  }
}

export default ForgotPassword;
