import React from 'react';

const IteratorField = (props) => {
  const { data, ids, children } = props;

  return ids.map((id) =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...child.props,
        record: data[id],
      })
    )
  );
};

export default IteratorField;
