import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Print = (props) => (
  <SvgIcon {...props}>
    <path
      d="M20 4a2 2 0 012 2v6a2 2 0 01-2 2h-2v5a1 1 0 01-1 1H7a1 1 0 01-1-1v-5H4a2 2 0 01-2-2V6a2 2 0 012-2zm-4 6H8v8h8zm-1.5 5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5zm-4-4a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5zm2 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5zm2 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5zM20 6H4v6h2v-2a1 1 0 110-2h12a1 1 0 110 2v2h2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Print;
