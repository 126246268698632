import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Confirmation } from 'components/common';

export const DeletePhoneActionField = forwardRef((props: any, ref: any) => {
  const { record } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDeletePhone = async () => {
    try {
      const { data } = await dataProvider.delete('blocklist', {
        id: record.phoneNumber,
      });
      refresh();
      return data;
    } catch (error: any) {
      notify(error.message);
    }
  };
  return (
    <Confirmation
      title="Delete phone"
      content="Are you sure you want to delete phone number from blocklist?">
      <MenuItem ref={ref} onClick={handleDeletePhone}>
        Delete
      </MenuItem>
    </Confirmation>
  );
});
