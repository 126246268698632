import React from 'react';
import RedirectActionButton from './RedirectActionButton';

const EditActionButton = ({ basePath, record }) => (
  <RedirectActionButton to="edit" basePath={basePath} id={record && record.id}>
    Edit
  </RedirectActionButton>
);

export default EditActionButton;
