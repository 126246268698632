import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Delivery = (props) => (
  <SvgIcon {...props}>
    <path
      d="M13 5a4 4 0 014 4h2a4 4 0 014 4v3a1 1 0 01-1 1h-1.05a2.5 2.5 0 01-4.9 0h-8.1a2.5 2.5 0 01-4.9 0H2a1 1 0 01-1-1V6a1 1 0 011-1zm5.5 11a.5.5 0 00-.09.992l.09.008a.5.5 0 000-1zm-13 0a.5.5 0 100 1 .5.5 0 000-1zM13 7H3v8h.5c.456-.607 1.182-1 2-1 .818 0 1.544.393 2 1H15V9a2 2 0 00-2-2zm6 4h-2v3.5a2.496 2.496 0 013.5.5h.5v-2a2 2 0 00-2-2zm-7-2a1 1 0 110 2H6a1 1 0 110-2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Delivery;
