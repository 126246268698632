import Auth from '@aws-amplify/auth';
import {
  ConfirmSignIn,
  RequireNewPassword,
  TOTPSetup,
  VerifyContact,
  withAuthenticator,
} from 'aws-amplify-react';
import { Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import React from 'react';
import { Admin, Resource } from 'react-admin';

import Layout from './components/layout/Layout';
import { SignIn } from './pages/auth';
import { config } from './services/auth';
import data from './services/data';
import {
  users,
  devices,
  shipments,
  debtors,
  pushNotifications,
  blocklist,
  fraudDashboard,
  claims,
  creditCodes,
} from './pages';
import ClaimPhotos from 'pages/claims/ClaimPhotos';

Auth.configure(config);

const history = createHistory();

const App = () => (
  <Admin
    dataProvider={data}
    layout={Layout}
    history={history}
    customRoutes={[<Route key="claimPhotos" path="/claim/:id/photos" component={ClaimPhotos} />]}>
    <Resource name="users" options={{ label: 'Users', menu: 'primary' }} {...users} />
    <Resource name="devices" options={{ label: 'Device Search', menu: 'primary' }} {...devices} />
    <Resource name="debtors" options={{ label: 'Debtor Accounts', menu: 'primary' }} {...debtors} />
    <Resource
      name="pushNotifications"
      options={{ label: 'Push notifications', menu: 'primary' }}
      {...pushNotifications}
    />
    <Resource
      name="blocklist"
      options={{ label: 'Phone blocklist', menu: 'primary' }}
      {...blocklist}
    />
    <Resource
      name="fraudDashboard"
      options={{ label: 'Fraud dashboard', menu: 'primary' }}
      {...fraudDashboard}
    />
    <Resource
      name="creditCodes"
      options={{ label: 'Campaigns', menu: 'primary' }}
      {...creditCodes}
    />
    <Resource name="claims" options={{ label: 'Claims', menu: 'primary' }} {...claims} />

    <Resource name="shipments" {...shipments} />
    <Resource name="userHistory" />
    <Resource name="fraudTypes" />
    <Resource name="sharedWarehouses" />
    <Resource name="userActivity" />
    <Resource name="userDevices" />
    <Resource name="profile" />
    <Resource name="tableSettings" />
    <Resource name="rowsPerPageSettings" />
    <Resource name="shipments" />
    <Resource name="debtorsStatuses" />
    <Resource name="userActivityTypes" />
    <Resource name="claimPhotos" />
    <Resource name="claimReceipt" />
    {/* <Resource name="devices"/> */}
  </Admin>
);

export default withAuthenticator(App, false, [
  <SignIn />,
  <ConfirmSignIn />,
  <RequireNewPassword />,
  <VerifyContact />,
  <TOTPSetup />,
]);
