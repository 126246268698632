import { get } from 'lodash';

const addressToString = (address, options?: { withName?: boolean; withCountry?: boolean }) => {
  const { withName = false, withCountry = true } = options || {};

  return [
    withName && get(address, 'name'),
    get(address, 'addressLine3'),
    get(address, 'addressLine2'),
    get(address, 'addressLine1'),
    get(address, 'city'),
    [
      get(address, 'state') ||
        get(address, 'stateProvinceCode') ||
        get(address, 'stateOrProvince.code'),
      get(address, 'zipCode') || get(address, 'postalCode'),
    ]
      .filter((s) => s)
      .join(' '),
    withCountry &&
      (get(address, 'country') ||
        get(address, 'countryCode') ||
        get(address, 'stateOrProvince.country.name')),
  ]
    .filter((s) => s)
    .join(', ');
};

export default addressToString;
