import { IconButton } from '@material-ui/core';
import { Input } from 'components/form/index';
import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const Adornment = ({ onClick, type }) => (
  <IconButton onClick={onClick} aria-label="show/hide">
    {type !== 'password' && <VisibilityIcon color="disabled" />}
    {type === 'password' && <VisibilityOffIcon color="disabled" />}
  </IconButton>
);

const PasswordInput = ({ ...props }: any) => {
  const [type, setType] = useState('password');

  return (
    <Input
      {...props}
      type={type}
      inputProps={{
        InputProps: {
          endAdornment: (
            <Adornment
              type={type}
              onClick={() => setType(type === 'password' ? 'text' : 'password')}
            />
          ),
        },
      }}
    />
  );
};

export default PasswordInput;
