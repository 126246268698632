import { Chip, createStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { get } from 'lodash';

const styles = (theme) =>
  createStyles({
    chip: {
      height: 18,
      fontSize: 12,
      fontWeight: 'bold',
    },
  });

const StatusField = ({ classes, className = '', markedAsShipped = false, record = {} }) => (
  <Chip
    className={classNames(classes.chip, className)}
    style={{ backgroundColor: get(record, 'bgColor'), color: get(record, 'color') }}
    label={!markedAsShipped ? get(record, 'name') : 'Marked As Shipped'}
  />
);

export default withStyles(styles)(StatusField);
