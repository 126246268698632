import React from 'react';
import { TwoLinesLabel } from 'components/common';
import UserStatusField from './UserStatusField';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  labelWithGap: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  root: {
    padding: '8px 0',
  },
}));

const UserInfo = ({ user }) => {
  const classes = useStyles();

  if (!user?.id) {
    return null;
  }

  if (user.deletedAt) {
    return (
      <TwoLinesLabel
        small
        primary={
          <b style={{ color: '#FF0000' }}>{`${user.firstName} ${user.lastName} (deleted)`}</b>
        }
        secondary={<span style={{ color: '#FF0000' }}>{`${user.email}, ${user.phone}`}</span>}
      />
    );
  }

  const primary = (
    <Box className={classes.labelWithGap}>
      <b>{`${user.firstName} ${user.lastName}`}</b>
      <UserStatusField record={user} />
    </Box>
  );

  const secondary = (
    <Box>
      <Box className={classes.labelWithGap}>
        <span>{user.email}</span>
        <span>{user.phone}</span>
      </Box>
      {!!user.singlePayerAccountNumber && (
        <Box>UPS Single Payer Account: {user.singlePayerAccountNumber}</Box>
      )}
    </Box>
  );

  return (
    <TwoLinesLabel
      autoSize
      classes={{ root: classes.root }}
      small
      primary={primary}
      secondary={secondary}
    />
  );
};

export default UserInfo;
