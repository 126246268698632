import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Ascending = (props) => (
  <SvgIcon {...props}>
    <path
      d="M11 7a1 1 0 110 2H5a1 1 0 110-2zm-2 4a1 1 0 110 2H5a1 1 0 110-2zm-2 4a1 1 0 110 2H5a1 1 0 110-2zm9 3a1 1 0 001-1V9.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a.998.998 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L15 9.414V17a1 1 0 001 1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Ascending;
