import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import moment, { Moment } from 'moment';
import { FORM_ERROR } from 'final-form';
import { useDataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';

import ChipsArrayInput from 'components/inputs/ChipsArrayInput';
import ActionButton from 'components/buttons/ActionButton';
import { Dialog } from 'components/common';
import DateInputField from 'components/form/DateInputField';
import { required } from 'components/form/validationRules';
import { TransactionType, UserDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

interface FormValues {
  type: TransactionType[];
  from: Moment | null;
  to: Moment | null;
}

const options = Object.values(TransactionType).map((type) => ({ value: type, label: type }));

interface Props {
  user: UserDetails;
}

const ExportTransactionsButton = ({ user }: Props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { id } = useParams();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExportTransactions = async (values: FormValues) => {
    try {
      const { from, type, to } = values;

      if (moment(from).isAfter(moment(to))) {
        throw new Error('Start date is later than end date. Please check your entries');
      }

      const { data } = await dataProvider.get('exportUserTransactions', {
        id,
        ...(from && { 'filter.from': `${from?.format('YYYY-MM-DD')}T00:00:00.000Z` }),
        ...(to && { 'filter.to': `${to?.format('YYYY-MM-DD')}T23:59:59.999Z` }),
        ...(!!type && !!type?.length && { 'filter.type': type.join(',') }),
      });

      const url = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${user.email}_${moment().format('YYYY-MM-DDTHH:mm')}.csv`);
      document.body.appendChild(link);

      link.click();
      link.parentNode?.removeChild(link);

      return data;
    } catch (error: any) {
      return { [FORM_ERROR]: error.message };
    }
  };

  return (
    <>
      <ActionButton onClick={handleOpen}>Export transactions</ActionButton>

      {open && (
        <Form<FormValues>
          initialValues={{
            type: [],
            from: null,
            to: null,
          }}
          onSubmit={handleExportTransactions}
          render={({ handleSubmit, submitting, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Dialog
                  onConfirm={handleSubmit}
                  title="Transactions export"
                  open={open}
                  onCancel={handleClose}
                  submitting={submitting}
                  error={submitError}>
                  <Field
                    type="checkbox"
                    name="type"
                    render={({ input }) => (
                      <ChipsArrayInput
                        choices={options}
                        optionValue="value"
                        optionLabel="label"
                        source="types"
                        label="Type"
                        input={input}
                        showSelectAll={false}
                      />
                    )}
                  />

                  <div className={classes.fieldContainer}>
                    <Typography>Start date:</Typography>

                    <DateInputField name="from" label="Select date" validate={required} />
                  </div>

                  <div className={classes.fieldContainer}>
                    <Typography>End date:</Typography>

                    <DateInputField name="to" label="Select date" validate={required} />
                  </div>
                </Dialog>
              </form>
            );
          }}
        />
      )}
    </>
  );
};

export default ExportTransactionsButton;
