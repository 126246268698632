import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import BackActionButton from 'components/buttons/BackActionButton';
import UserInfo from './UserInfo';
import AdminNotesCard from './AdminNotesCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between',
  },

  left: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  backButton: {
    paddingRight: '8px',
  },
}));

const UserDetailsHeader = ({ user }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.left}>
        <BackActionButton className={classes.backButton} />

        <UserInfo user={user} />
      </Box>

      <AdminNotesCard user={user} />
    </Box>
  );
};

export default UserDetailsHeader;
