import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Popover from '@material-ui/core/Popover';
import { createStyles, withStyles } from '@material-ui/core';
import ToggleButton from 'components/buttons/ToggleButton';
import moment from 'moment';
import { isEqual } from 'lodash';
import DateRangeInput from 'components/inputs/DateRangeInput';
import { isInclusivelyBeforeDay, isInclusivelyAfterDay } from 'react-dates';

const styles = (theme) =>
  createStyles({
    button: {
      '&:not(:last-child)': {
        borderRight: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '&:not(:first-child)': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    group: {
      paddingTop: theme.spacing(2),
    },
    popoverPaper: {
      overflow: 'visible',
    },
    labelIcon: {
      marginRight: theme.spacing(2),
    },
    label: {
      display: 'flex',
      alignItems: 'center',
    },
    calendarWrapper: {
      '& .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:hover, .CalendarDay__blocked_out_of_range:active':
        {
          backgroundColor: '#CACCCD',
          borderColor: '#CACCCD',
          color: '#82888a',
        },
    },
  });

const defaultOptions = [
  {
    label: 'All time',
    value: {
      from: null,
      to: moment().endOf('day').toISOString(),
    },
  },
  {
    label: 'Today',
    value: {
      from: moment().startOf('day').toISOString(),
      to: moment().endOf('day').toISOString(),
    },
  },
  {
    label: 'Last 3 days',
    value: {
      from: moment().subtract(2, 'days').startOf('day').toISOString(),
      to: moment().endOf('day').toISOString(),
    },
  },
];

interface IFilterDateInputProps {
  classes?;
  input?;
  meta?;
  label?;
  source?;
  allowEmpty?;
  icon?;
  options?;
  preventFuture?;
  preventPast?;
  datePickerFormat?;
}

const getCustomRangeLabel = (value, format = 'L') => {
  if (moment(value.from).isSame(moment(value.to), 'day')) {
    return moment(value.from).format(format);
  }
  return `${value.from ? moment(value.from).format(format) : '*'} - ${
    value.to ? moment(value.to).format(format) : '*'
  }`;
};

const dateToCorrectFormat = (date, format: string | null) =>
  format ? date.format(format) : date.toISOString();

const FilterDateInput = ({
  classes,
  input: { value, onChange },
  meta: { touched, error },
  label,
  icon,
  options = defaultOptions,
  datePickerFormat = null,
  preventFuture = false,
  preventPast = false,
}: IFilterDateInputProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const getIsCustomRange = useCallback(
    (value) =>
      Boolean(value && value.from && value.to) &&
      options.reduce((acc, option) => {
        if (
          moment(option.value.from as any).isSame(value.from) &&
          moment(option.value.to).isSame(value.to)
        ) {
          return false;
        }

        return acc;
      }, true),
    [options]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dateRangePickerProps = {
    input: {
      value,
      onChange: ({ startDate, endDate }) =>
        onChange({
          from: startDate ? dateToCorrectFormat(startDate.startOf('day'), datePickerFormat) : null,
          to: endDate
            ? dateToCorrectFormat(endDate.endOf('day'), datePickerFormat)
            : dateToCorrectFormat(startDate.startOf('day'), datePickerFormat),
        }),
    },
  };

  const isCustomRange = getIsCustomRange(value);

  return (
    <FormControl className={classes.root}>
      <FormLabel className={classes.label}>
        {icon && React.createElement(icon.type, { className: classes.labelIcon })}
        {label}
      </FormLabel>
      <Box className={classes.group}>
        {options.map((option) => (
          <ToggleButton
            key={option.label}
            className={classes.button}
            toggled={isEqual(value.from, option.value.from) && isEqual(value.to, option.value.to)}
            error={error}
            onClick={() => onChange(option.value)}>
            {option.label}
          </ToggleButton>
        ))}
        <ToggleButton
          className={classes.button}
          toggled={isCustomRange}
          error={error}
          onClick={handleClick}>
          {isCustomRange ? getCustomRangeLabel(value) : 'Select range'}
        </ToggleButton>
        <Popover
          id={id}
          classes={{ paper: classes.popoverPaper }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <span className={classes.calendarWrapper}>
            <DateRangeInput
              {...dateRangePickerProps}
              handleClose={handleClose}
              isOutsideRange={(day) =>
                (preventFuture || !isInclusivelyAfterDay(day, moment())) &&
                (preventPast || !isInclusivelyBeforeDay(day, moment()))
              }
            />
          </span>
        </Popover>
      </Box>
    </FormControl>
  );
};

export default withStyles(styles)(FilterDateInput);
