import React from 'react';
import Input from './Input';

const CurrencyInput = (props) => {
  const handleChange = (value: string) =>
    value
      .replace(/[^\d.]/g, '')
      .replace(/\.(?=.*\.)/g, '')
      .replace(/^(\d+\.\d{0,2})\d*$/, '$1');

  return <Input {...props} formatValue={handleChange} />;
};

export default CurrencyInput;
