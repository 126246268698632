import { Chip, createStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { get } from 'lodash';

const styles = (theme) =>
  createStyles({
    chip: {
      height: 18,
      fontSize: 12,
      fontWeight: 'bold',
      width: '68px',
      textAlign: 'center',
      marginTop: '12px',
    },
  });

const ReturnLabelChip = ({ classes, className = '', record = {}, source, ...props }) => {
  if (get(record, source) !== 'RETURN') return null;
  else return <Chip className={classNames(classes.chip, className)} label="Return" {...props} />;
};

export default withStyles(styles)(ReturnLabelChip);
