import { Balance, Print } from 'icons';
import formatPrice from 'services/helpers/formatPrice';

export const getCurrency = (currencyCode) => {
  const isLabel = currencyCode === 'LABEL';

  return {
    caption: isLabel ? 'Free Labels' : 'Balance',
    icon: isLabel ? Print : Balance,
    format: (amount) => (isLabel ? amount : formatPrice(amount, { currency: currencyCode })),
  };
};

export const getCommand = (command) => {
  switch (command) {
    case 'refill':
      return {
        caption: 'Refill',
        calc: (value, amount) => value + amount,
      };

    case 'charge':
      return {
        caption: 'Charge',
        calc: (value, amount) => value - amount,
      };

    case 'refund':
      return {
        caption: 'Refund',
        calc: (value, amount) => value - amount,
      };
  }
};

export const getAmountObject = (balance, currencyCode) => {
  return balance?.find((acc) => acc.currency === currencyCode);
};
