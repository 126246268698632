import { withStyles } from '@material-ui/core';
import { ImageField } from 'react-admin';

const styles = (theme) => ({
  image: {
    maxWidth: 20,
    maxHeight: 20,
    margin: 0,
    marginRight: '0.5rem',
  },
});

export default withStyles(styles)(ImageField);
