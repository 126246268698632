import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { Ascending, Descending } from 'icons';
import { FieldTitle, useTranslate } from 'ra-core';
import React, { useMemo } from 'react';
import classnames from 'classnames';

interface DatagridHeaderCellProps {
  className;
  classes?;
  field;
  currentSort;
  updateSort;
  resource;
  isSorting;
  sortable?;
  key;
  padding;
}

const useStyles = makeStyles({
  icon: {
    display: 'none',
  },
  active: {
    '& $icon': {
      display: 'inline',
    },
  },
});

const DatagridHeaderCell = ({
  className,
  classes: classesOverride,
  field,
  currentSort,
  updateSort,
  resource,
  isSorting,
  sortable,
  ...rest
}: DatagridHeaderCellProps) => {
  const classes = useStyles({ classes: classesOverride });
  const translate = useTranslate();

  return useMemo(
    () => (
      <TableCell
        className={classnames(className, field.props.headerClassName)}
        align={field.props.textAlign}
        variant="head"
        {...rest}>
        {field.props.sortable !== false && (field.props.sortBy || field.props.source) ? (
          <Tooltip
            title={translate('ra.action.sort')}
            placement={field.props.textAlign === 'right' ? 'bottom-end' : 'bottom-start'}
            enterDelay={300}>
            <TableSortLabel
              active={currentSort.field === (field.props.sortBy || field.props.source)}
              data-sort={field.props.sortBy || field.props.source}
              onClick={updateSort}
              classes={classes}
              IconComponent={currentSort.order === 'ASC' ? Ascending : Descending}>
              <FieldTitle
                label={field.props.label}
                source={field.props.source}
                resource={resource}
              />
            </TableSortLabel>
          </Tooltip>
        ) : (
          <FieldTitle label={field.props.label} source={field.props.source} resource={resource} />
        )}
      </TableCell>
    ),
    [updateSort, sortable, isSorting]
  ); //eslint-disable-line
};

export default DatagridHeaderCell;
