const isFirefox = /Gecko\/\d/.test(navigator.userAgent);
const firefoxDelay = 1000;
let iframe;

const printPdf = function (url, notify) {
  if (iframe) {
    iframe.parentNode.removeChild(iframe);
  }

  iframe = document.createElement('iframe');
  iframe.style.cssText =
    'width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0';

  let xhr = new XMLHttpRequest();
  try {
    xhr.responseType = 'arraybuffer';
  } catch (e) {
    window.open(url, '_blank');
    return;
  }

  xhr.addEventListener('load', function () {
    if (xhr.status === 200 || xhr.status === 201) {
      let pdfBlob = new Blob([xhr.response], { type: 'application/pdf' });
      let iframeUrl = URL.createObjectURL(pdfBlob);
      iframe.src = iframeUrl;

      iframe.addEventListener('load', function () {
        function printIframe() {
          try {
            iframe.focus();
            try {
              iframe.contentWindow.document.execCommand('print', false, null);
            } catch (e) {
              iframe.contentWindow.print();
            }
          } catch (error) {
            if (notify) notify(error.message, 'error');
            else console.error('Print failed: ' + error, error);
          } finally {
            iframe.style.visibility = 'hidden';
            iframe.style.left = '-1px';
            URL.revokeObjectURL(iframeUrl);
          }
        }

        if (isFirefox) {
          window.setTimeout(printIframe, firefoxDelay);
        } else {
          printIframe();
        }
      });

      document.body.appendChild(iframe);
    }
  });

  xhr.open('GET', url, true);
  xhr.send();
};

export default printPdf;
