import { GET_LIST, GET_MANY } from 'react-admin';

const getDebtors = ({ response, url, options, httpClient, params, apiUrl }) => {
  const data = response.json;

  return {
    ...response,
    json: data.map((debtor) => ({
      ...debtor,
      id: debtor.userId,
    })),
  };
};

export default {
  [GET_LIST]: getDebtors,
  [GET_MANY]: getDebtors,
};
