import { Button, createStyles, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const styles = (theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      alignItems: 'center',
    },
    button: {
      padding: '12px 20px',
      whiteSpace: 'nowrap',
    },
  });

const Actions = (props) => {
  const {
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    hasCreate,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    children,
    classes,
    createLabel,
  } = props;

  return useMemo(
    () => (
      <span className={classes.root}>
        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
          })}
        {filters &&
          React.cloneElement(filters, {
            resource,
            basePath,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {hasCreate && (
          <Button
            className={classes.button}
            color="secondary"
            variant="contained"
            aria-label="Create"
            component={Link}
            to={`${basePath}/create`}
            startIcon={<AddIcon />}>
            {createLabel || 'Create'}
          </Button>
        )}
        {children}
      </span>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total]
  ); //eslint-disable-line
};

export default withStyles(styles)(Actions);
