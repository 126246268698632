import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { statuses } from '../constants';

const ChangeStatusAction = forwardRef((props: any, ref: any) => {
  const { record } = props;

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleChangeStatus = async (code) => {
    try {
      await dataProvider.update('claims', { status: code, id: record.id });

      refresh();
    } catch (error: any) {
      notify(error?.message);
    }
  };

  return (
    <>
      {statuses.map(({ label, code }) => (
        <MenuItem ref={ref} onClick={() => handleChangeStatus(code)}>
          {label}
        </MenuItem>
      ))}
    </>
  );
});

export default ChangeStatusAction;
