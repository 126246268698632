import Auth from '@aws-amplify/auth';
import { Button, FormLabel, Grid, Typography } from '@material-ui/core';
import { SignIn as AmplifySignIn } from 'aws-amplify-react';
import { Checkbox, Input } from 'components/form';
import PasswordInput from 'components/form/PasswordInput';
import { required } from 'components/form/validationRules';
import AuthLayout, { useStyles } from 'components/layout/AuthLayout';
import { FORM_ERROR } from 'final-form';
import { get } from 'lodash';
import React from 'react';
import { GET_ONE, UPDATE } from 'react-admin';
import { Form } from 'react-final-form';
import dataProvider from 'services/data/localstorage';

const useSignInOptions = () => {
  const { data } = dataProvider(GET_ONE, 'signInOptions', { id: 'local' }) || { data: {} };
  const update = ({ data }) => dataProvider(UPDATE, 'signInOptions', { id: 'local', data });

  return [data, update];
};

const SignInView = ({ onSignIn }) => {
  const classes = useStyles();
  const [data, update] = useSignInOptions();
  const rememberMe = get(data, 'rememberMe', false);

  const initialValues = {
    rememberMe: rememberMe,
    username: rememberMe ? get(data, 'username', '') : '',
    password: '',
  };

  const onSubmit = ({ username, password, rememberMe }) => {
    update({ data: { rememberMe, username } });
    return onSignIn({ username, password });
  };

  return (
    <AuthLayout>
      <Typography variant="h6" align="center">
        Sign in
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, submitError, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Input
              name="username"
              label="Username"
              fullWidth
              validate={required}
              parse={(v) => v.trim()}
            />
            <PasswordInput
              name="password"
              label="Password"
              fullWidth
              validate={required}
              parse={(v) => v.trim()}
            />
            <Grid container>
              <Grid item xs={6}>
                <Checkbox name="rememberMe" label="Remember me" />
              </Grid>
            </Grid>
            {submitError && <FormLabel error>{submitError}</FormLabel>}
            <Button
              className={classes.signInButton}
              disabled={submitting}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              type="submit">
              SIGN IN
            </Button>
          </form>
        )}
      />
    </AuthLayout>
  );
};

class SignIn extends AmplifySignIn {
  onSignIn = ({ username, password }) => {
    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    return Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.changeState('confirmSignIn', user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.changeState('requireNewPassword', user);
        } else if (user.challengeName === 'MFA_SETUP') {
          this.changeState('TOTPSetup', user);
        } else if (
          user.challengeName === 'CUSTOM_CHALLENGE' &&
          user.challengeParam &&
          user.challengeParam.trigger === 'true'
        ) {
          this.changeState('customConfirmSignIn', user);
        } else {
          this.checkContact(user);
        }
      })
      .catch((err) => {
        if (err.code === 'UserNotConfirmedException') {
          this.changeState('confirmSignUp', { username });
        } else if (err.code === 'PasswordResetRequiredException') {
          this.changeState('forgotPassword', { username });
        } else if (err.code === 'UserNotFoundException') {
          return { [FORM_ERROR]: 'Incorrect username or password.' };
        } else {
          return { [FORM_ERROR]: err.message ? err.message : JSON.stringify(err) };
        }
      });
  };

  showComponent(theme) {
    return <SignInView onSignIn={this.onSignIn} />;
  }
}

export default SignIn;
