import React, { useEffect, useState } from 'react';
import { TextField, DateField, useRedirect, useDataProvider, useVersion } from 'react-admin';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import { keyBy } from 'lodash';

import { Datagrid } from 'components/list';
import Pagination from 'components/pagination/Pagination';
import { ActionField, PriceField } from 'components/fields';

import EnableUserAction from './EnableUserAction';
import BypassFraudAction from './BypassFraudAction';
import TextFieldWithAction from './TextFieldWithAction';
import InfoField from './InfoField';
import NameField from './NameField';

const useStyles = makeStyles((theme) => ({
  expandHeader: {
    width: 'auto',
  },
  expand: {
    backgroundColor: theme.palette.background.default,
  },
  expandCell: {
    padding: '16px 0',
  },
}));

const ExpandedArea = (props) => {
  const { record } = props;
  const redirect = useRedirect();

  const data = keyBy(record.users, ({ uniqueId }) => uniqueId);

  const ids = record.users.map(({ uniqueId }) => uniqueId);

  return (
    <Datagrid
      data={data}
      ids={ids}
      resource="sharedWarehouses"
      currentSort={{ field: 'id' }}
      rowClick={(id) => {
        redirect('show', '/users', id);
      }}>
      <NameField nameSource="userName" deletedSource="warehouseDeletedAt" label="Name" />
      <InfoField label="" />
      <TextField source="phoneNumber" label="Phone" />
      <TextField source="email" label="Email" />
      <DateField
        source="createdAt"
        label="Registered"
        options={{
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }}
      />
      <TextField source="shipments" label="Shipments" />
      <PriceField source="totalSpentAmount" label="Spend" />
      <ActionField>
        <EnableUserAction />
        <BypassFraudAction />
      </ActionField>
    </Datagrid>
  );
};

const SharedWarehousesList = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const version = useVersion();

  const [warehouses, setWarehouses] = useState<any>([]);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  useEffect(() => {
    const getWarehouses = async () => {
      const { data, total } = await dataProvider.getList('sharedWarehouses', {
        pagination: { page, perPage },
      });

      setCurrentTotal(total);
      setWarehouses(data);
    };

    getWarehouses();
  }, [dataProvider, page, perPage, version]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <Card>
      <Datagrid
        expand={<ExpandedArea />}
        isRowExpandable
        rowClick="expand"
        classes={{
          expandHeader: classes.expandHeader,
          expandRoot: classes.expand,
          expandCell: classes.expandCell,
        }}
        showExpandButton={false}
        data={keyBy(warehouses, 'id')}
        ids={warehouses.map(({ id }) => id)}
        resource="sharedWarehouses"
        currentSort={{ field: 'id', order: 'DESC' }}>
        <TextField source="warehouseAddress.addressLine" label="From Address" />
        <TextFieldWithAction source="users.length" label="Users" textAlign="right" />
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </Card>
  );
};

export default SharedWarehousesList;
