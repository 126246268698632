import React, { useEffect, useState } from 'react';
import { useDataProvider, useVersion, useListController } from 'react-admin';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import NotificationStatusField from './components/NotificationStatusField';
import Pagination from 'components/pagination/Pagination';
import PushNotificationDialog from './components/PushNotificationDialog';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '75%',
    minHeight: 0,
    '& .filter-field': {
      width: '75%',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    maxWidth: 200,
    boxShadow: 'none',
    minWidth: 160,
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  card: {
    borderRadius: 16,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    margin: theme.spacing(1, 0),
    alignItems: 'center',
  },
  statusCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(3),
  },
  headerText: {
    fontSize: 14,
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
  },
  notificationMessage: {
    wordBreak: 'break-all',
    paddingRight: '16px',
  },
}));

const PushNotificationsList = (props) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const version = useVersion();
  const [notifications, setNotifications] = useState<any>([]);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const controllerProps = useListController(props);

  const {
    page,
    perPage,
    setPage,
    setPerPage,
    filterValues: { q },
  } = controllerProps;

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList('pushNotifications', {
        pagination: { page, perPage },
        filter: { q },
      })
      .then(({ data, total }) => {
        setNotifications(() => data);
        setCurrentTotal(total);
      })
      .finally(() => setLoading(false));
  }, [dataProvider, version, page, perPage, q]);

  const updatePerPage = (newPerPage) => {
    if (page > Math.ceil(currentTotal / newPerPage)) {
      setPage(1);
    }
    setPerPage(newPerPage);
  };

  return (
    <>
      <Box className={classes.button}>
        <PushNotificationDialog />
      </Box>
      <Grid container className={classes.header}>
        <Grid item xs={3}>
          <Typography className={classes.headerText}>Title</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.headerText}>Message</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.headerText}>Date</Typography>
        </Grid>
        <Grid item xs={1} className={classes.statusCell}>
          <Typography className={classes.headerText}>Status</Typography>
        </Grid>
      </Grid>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {notifications?.map((notification) => {
            return (
              <Grid container className={classes.card} key={notification.id}>
                <Grid item xs={3}>
                  <Typography>{notification.title}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography className={classes.notificationMessage}>
                    {notification.body}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{moment(notification.lastSentAt).fromNow()}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.statusCell}>
                  <NotificationStatusField />
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={updatePerPage}
        total={currentTotal}
      />
    </>
  );
};

export default PushNotificationsList;
