import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDataProvider, useRefresh } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

import ActionButton from 'components/buttons/ActionButton';
import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { required } from 'components/form/validationRules';

interface FormValues {
  phoneNumber: string;
  reason: string;
}

const AddBlockedPhoneButton = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [open, setOpen] = useState<boolean>(false);

  const handleAddPhone = async (values: FormValues) => {
    try {
      const { data } = await dataProvider.post('blocklist', {
        data: { ...values },
      });
      refresh();
      setOpen(false);
      return data;
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <>
      {open && (
        <Form<FormValues>
          onSubmit={handleAddPhone}
          render={({ handleSubmit, submitting }) => {
            return (
              <Dialog
                title="Block phone number"
                onConfirm={handleSubmit}
                onCancel={() => setOpen(false)}
                open={open}
                submitting={submitting}>
                <Input name="phoneNumber" label="Phone" validate={required} />
                <Input name="reason" label="Reason" validate={required} />
              </Dialog>
            );
          }}
        />
      )}
      <ActionButton onClick={() => setOpen(true)} startIcon={<AddIcon />}>
        Add phone
      </ActionButton>
    </>
  );
};

export default AddBlockedPhoneButton;
