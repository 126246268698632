import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Download from '@material-ui/icons/GetApp';
import moment from 'moment';

import ActionButton from 'components/buttons/ActionButton';
import { CircularProgress } from '@material-ui/core';

let intervalId;

const ExportDebtorsButton = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);

  const handleExportDebtors = async () => {
    try {
      setLoading(true);
      const {
        data: { executionArn },
      } = await dataProvider.get('exportDebtors');

      intervalId = setInterval(async () => {
        const {
          data: { status, downloadURL },
        } = await dataProvider.get('exportDebtorsStatus', {
          arn: executionArn,
        });
        if (status !== 'RUNNING') {
          setLoading(false);
          clearInterval(intervalId);
          if (status === 'SUCCEEDED' && downloadURL) {
            const data: any = await fetch(downloadURL);
            const url = window.URL.createObjectURL(new Blob([await data.text()]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Debtors_${moment().format()}.csv`);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
          }
        }
      }, 5000);
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <ActionButton disabled={loading} onClick={handleExportDebtors}>
      {loading ? <CircularProgress size={20} /> : <Download />}
    </ActionButton>
  );
};

export default ExportDebtorsButton;
