import { stringify } from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import resourceUrl from './resources';
import responses from './responses';

interface RequestOptions {
  method?: string;
  body?: string;
  headers?: any;
}

interface GetListQueryInterface {
  sort?: string;
  page?: number;
  size?: number;
  filter?: string;
}

export const GET = 'GET';
export const POST = 'POST';

const USERS_PAGE_SIZE = 100;

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const performQuery = (apiUrl, httpClient = fetchUtils.fetchJson, isExternal = false) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url;

    if (isExternal) {
      url = `${apiUrl}?${resourceUrl(resource, params, isExternal)}`;
    } else {
      url = `${apiUrl}/${resourceUrl(resource, params)}`;
    }

    const options: RequestOptions = {
      headers: params && params.headers,
    };
    switch (type) {
      case GET:
        url = isExternal ? url : `${url}?${stringify(params)}`;
        break;
      case GET_LIST: {
        if (params) {
          const query: GetListQueryInterface = {};
          const { pagination, sort, filter } = params;

          if (pagination) {
            const { page, perPage } = pagination;
            query.page = page;
            query.size = resource === 'users' ? USERS_PAGE_SIZE : perPage;
          }

          if (sort) {
            const { field, order } = sort;
            if (resource === 'debtors') {
              query.sort = `${field},${order}`;
            } else {
              query.sort = field;
              query[`${field}.dir`] = order;
            }
          }

          url = isExternal ? url : `${url}?${stringify(query)}`;

          if (filter && !isExternal) {
            for (let key in filter) {
              const filterElement = filter[key];

              if (filterElement === undefined || key.startsWith('_')) {
                continue;
              }

              if (key === 'q') {
                url += `&q=${encodeURIComponent(filterElement)}`;
              } else if (key === 'search') {
                url += `&search=${encodeURIComponent(filterElement)}`;
              } else if (resource === 'blocklist' && key === 'phone') {
                url += `&phone=${encodeURIComponent(filterElement)}`;
              } else if (resource === 'userActivity') {
                url += `&${key}=${encodeURIComponent(filterElement)}`;
              } else if (typeof filterElement === 'string' || typeof filterElement === 'boolean') {
                if (resource === 'debtors') {
                  url += `&${encodeURIComponent(key)}=${encodeURIComponent(filterElement)}`;
                } else {
                  url += `&filter.${encodeURIComponent(key)}=${encodeURIComponent(filterElement)}`;
                }
              } else if (Array.isArray(filterElement)) {
                if (resource === 'debtors') {
                  url += `&${encodeURIComponent(key)}=${encodeURIComponent(
                    filterElement.join(',')
                  )}`;
                } else {
                  url += `&filter.${encodeURIComponent(
                    key
                  )}=${encodeURIComponent(filterElement.join(','))}`;
                }
              } else if (filterElement instanceof Object) {
                for (let sub in filterElement) {
                  if (Array.isArray(filterElement[sub])) {
                    url += `&filter.${encodeURIComponent(key)}.${encodeURIComponent(
                      sub
                    )}=${encodeURIComponent(filterElement[sub].join(','))}`;
                  } else if (filterElement[sub] instanceof Object) {
                    for (let subKey in filterElement[sub]) {
                      url += `&filter.${encodeURIComponent(
                        key
                      )}.${encodeURIComponent(sub)}.${encodeURIComponent(subKey)}=${encodeURIComponent(filterElement[sub][subKey])}`;
                    }
                  } else if (resource === 'debtors') {
                    url += `&${encodeURIComponent(key)}.${encodeURIComponent(
                      sub
                    )}=${encodeURIComponent(filterElement[sub])}`;
                  } else {
                    url += `&filter.${encodeURIComponent(
                      key
                    )}.${encodeURIComponent(sub)}=${encodeURIComponent(filterElement[sub])}`;
                  }
                }
              }
            }
          }
        }
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resourceUrl(resource)}/${params.id}`;
        break;
      case 'GET_NEST':
        url = `${apiUrl}/${resourceUrl(resource, params)}`;
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        };
        url += `?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        let query;

        if (params && params.pagination && params.sort) {
          const { page, perPage } = params.pagination;
          const { field, order } = params.sort;
          query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
              ...params.filter,
              [params.target]: params.id,
            }),
          };
        }

        url = `${apiUrl}/${resourceUrl(resource, params)}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url += `/${params.id}`;

        if (resource === 'claims') {
          url += `?status=${params.status}`;
        }

        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
        break;
      case POST:
      case CREATE:
        url = `${apiUrl}/${resourceUrl(resource, params)}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resourceUrl(resource)}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json, blob, body, total } = response;

    switch (type) {
      case GET_LIST:
        return {
          data: json,
          total: isExternal
            ? json.length
            : parseInt(headers.get('x-total-count')) || total || json.length,
        };
      case GET_MANY_REFERENCE:
        return {
          data: json,
          total: json.length,
        };
      case CREATE:
        return { data: { ...json, id: json.id } };
      case DELETE_MANY: {
        return { data: json || [] };
      }
      case GET_ONE:
        return {
          data: {
            ...json,
            id: json.id || 0,
          },
        };
      case DELETE:
        return {
          data: {
            id: json?.deleted[0],
          },
        };
      default:
        return { data: json || blob || body };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    const { url, options } = convertDataRequestToHTTP(type, resource, params);

    let responseFormatter = ({ response, url, options, httpClient, params, apiUrl }) => response;

    if (responses[resource] && responses[resource][type]) {
      responseFormatter = responses[resource][type];
    }

    return httpClient(url, options)
      .then((response) => responseFormatter({ response, url, options, httpClient, params, apiUrl }))
      .then((response) => convertHTTPResponse(response, type, resource, params));
  };
};

export default performQuery;
