import React from 'react';
import { TextField } from 'react-admin';
import { DescriptionOutlined } from '@material-ui/icons';

import { CardTitle } from 'components/common';

import AdminNotesEditor from './AdminNotesEditor';
import { Card, makeStyles } from '@material-ui/core';
import { UserDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    width: '50%',
    boxSizing: 'border-box',
    maxWidth: '600px',
  },
}));

interface Props {
  user: UserDetails;
}

const AdminNotesCard = ({ user }: Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardTitle
        icon={<DescriptionOutlined />}
        actions={<AdminNotesEditor record={user} title="Admin Note" />}>
        Admin note
      </CardTitle>

      <TextField record={user} source="note" variant="body1" data-test="note" />
    </Card>
  );
};

export default AdminNotesCard;
