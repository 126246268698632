import React from 'react';
import { TextField } from 'react-admin';
import { DescriptionOutlined } from '@material-ui/icons';
import { ShowLayout } from 'components/details';
import { CardTitle } from 'components/common';

const NoteSection = (props) => (
  <>
    <TextField {...props} data-test="note" source="order.note" variant="body1" />
  </>
);

const NoteCard = () => (
  <ShowLayout showingFields={['order.note']}>
    <CardTitle icon={<DescriptionOutlined />}>Note</CardTitle>
    <NoteSection />
  </ShowLayout>
);

export default NoteCard;
