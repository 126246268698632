import { Card, CardContent, Grid, Grow, makeStyles, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Footer from 'components/layout/Footer';
import { theme } from 'components/layout/Layout';
import logo from 'logo.svg';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

export const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  signInButton: {
    marginTop: theme.spacing(4),
  },
  signUp: {
    paddingTop: theme.spacing(4),
  },
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    flexDirection: 'column',
  },
  minWidthOneThird: {
    minWidth: 'calc(100vw / 3)',
  },
  cardContent: {
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  logo: {
    width: 90,
    margin: theme.spacing(4),
  },
  signinLink: {
    padding: theme.spacing(4, 0),
  },
});

const AuthLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Grid className={classes.root} container alignItems="center">
          <Grid className={classes.minWidthOneThird} item container justify="center">
            <img className={classes.logo} src={logo} alt={'logo'} />
          </Grid>
          <Typography variant="h6">Admin</Typography>
          <Grid item xs={4}>
            <Grow in>
              <Card className={classes.minWidthOneThird}>
                <CardContent className={classes.cardContent}>{children}</CardContent>
              </Card>
            </Grow>
          </Grid>
          <Footer />
        </Grid>
      </ThemeProvider>
    </Router>
  );
};

export default AuthLayout;
