import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

import { Confirmation } from 'components/common';

const DeleteCampaignActionField = forwardRef((props: any, ref: any) => {
  const { record } = props;

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDeleteCampaign = async () => {
    try {
      if (!record?.id) {
        return;
      }

      await dataProvider.delete('creditCodes', { id: record.id });

      refresh();
    } catch (error: any) {
      notify(error.message);
    }
  };

  return (
    <Confirmation title="Delete campaign" content="Are you sure you want to delete this campaign?">
      <MenuItem ref={ref} onClick={handleDeleteCampaign}>
        Delete
      </MenuItem>
    </Confirmation>
  );
});

export default DeleteCampaignActionField;
