import React from 'react';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import { makeStyles, Typography, Link } from '@material-ui/core';
import { LogoImage } from 'components/common';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const ProviderWithTrackingField = (props) => {
  const classes = useStyles();

  const {
    record,
    imageSource,
    source,
    smallProviderLogo,
    trackingNumberSource,
    trackingLinkSource,
  } = props;

  const image = get(record, imageSource);
  const primary = get(record, source);
  const trackingNumber = get(record, trackingNumberSource);
  const trackingLink = get(record, trackingLinkSource);

  return (
    <Box className={classes.root}>
      <LogoImage src={image} small={smallProviderLogo} />

      <Box className={classes.label}>
        <Typography variant="body1" color="textPrimary">
          {primary}
        </Typography>

        <Link href={trackingLink} target="_blank" underline="always">
          {trackingNumber}
        </Link>
      </Box>
    </Box>
  );
};

export default ProviderWithTrackingField;
