import React from 'react';
import { useRedirect } from 'react-admin';
import ActionButton from './ActionButton';

const RedirectActionButton = ({
  to,
  basePath = '',
  id,
  data,
  ...rest
}: {
  to: string | false;
  basePath?: string;
  id?: string | number;
  data?: any;
} & any) => {
  const redirect = useRedirect();

  const handleClick = () => {
    redirect(to, basePath, id, data);
  };

  return <ActionButton onClick={handleClick} {...rest} />;
};

export default RedirectActionButton;
