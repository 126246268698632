import { GET_LIST, GET_MANY } from 'react-admin';

const getBlocklist = ({ response, url, options, httpClient, params, apiUrl }) => {
  const data = response.json;

  return {
    ...response,
    json: data.map((phone) => ({
      ...phone,
      id: phone.phoneNumber,
    })),
  };
};

export default {
  [GET_LIST]: getBlocklist,
  [GET_MANY]: getBlocklist,
};
