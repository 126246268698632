import { GET_LIST, GET_MANY } from 'react-admin';

const getSharedWarehouses = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item) => ({
    ...item,
    id: item.warehouseAddress.addressLine,
    users: item.users.map((item, index) => ({ ...item, uniqueId: `${item.id}-${index}` })),
  })),
});

export default {
  [GET_LIST]: getSharedWarehouses,
  [GET_MANY]: getSharedWarehouses,
};
