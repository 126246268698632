import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  FormLabel,
  List,
  ListItem,
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core';
import { NewWindow } from 'icons';
import Dialog from './Dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, -4),
  },

  item: {
    padding: theme.spacing(2, 4),
    borderBottom: `1px solid ${theme.palette.divider}`,

    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },

  link: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.secondary.contrastText,
    marginLeft: theme.spacing(4),
  },

  linkIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    verticalAlign: 'middle',
  },
}));

const PresetList = ({ selectedPreset, fetchData, comparer, renderItem, onChange }) => {
  const classes = useStyles();

  const [data, setData] = useState<any[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchData()
      .then(setData)
      .catch((err) => setError(err.message));
  }, []); //eslint-disable-line

  if (!data) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <FormLabel error component="div">
        {error}
      </FormLabel>
    );
  }

  if (!data.length) {
    return <Typography variant="body1">No presets defined.</Typography>;
  }

  return (
    <List disablePadding={true} className={classes.root}>
      {data.map((preset) => {
        const isActive = comparer(preset, selectedPreset);

        return (
          <ListItem
            button
            className={classes.item}
            key={preset.id}
            onClick={() => onChange(preset)}>
            {renderItem(preset, isActive)}
          </ListItem>
        );
      })}
    </List>
  );
};

const PresetDialog = ({
  open,
  value,
  fetchData,
  comparer,
  renderItem,
  link,
  onChange,
  onClose,
}) => {
  const classes = useStyles();

  const [selectedPreset, setSelectedPreset] = useState(value);

  const handleChangePreset = (newPreset) => {
    setSelectedPreset(newPreset);
  };

  const handleApply = () => {
    onChange(selectedPreset);
    onClose();
  };

  const handleCancel = () => {
    setSelectedPreset(value);
    onClose();
  };

  return (
    <Dialog
      open={open}
      title={
        <>
          Apply Preset
          {link && (
            <Link className={classes.link} href={link} target="_blank">
              Manage presets <NewWindow className={classes.linkIcon} />
            </Link>
          )}
        </>
      }
      confirmText="Apply"
      onConfirm={handleApply}
      onCancel={handleCancel}>
      <PresetList
        selectedPreset={selectedPreset}
        fetchData={fetchData}
        comparer={comparer}
        renderItem={renderItem}
        onChange={handleChangePreset}
      />
    </Dialog>
  );
};

export default PresetDialog;
