import { LogoField } from 'components/fields/index';
import React from 'react';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import { createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 0,
    },
  });

interface IStoreFieldProps {
  record?;
  logoSource?;
  labelSource?;
  source?;
  sortBy?;
  label?;
  classes?;
}

const StoreField = ({ record, logoSource, labelSource, classes }: IStoreFieldProps) => (
  <Box className={classes.root}>
    <LogoField record={record} source={logoSource} />
    {get(record, labelSource)}
  </Box>
);

export default withStyles(styles)(StoreField);
