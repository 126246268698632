import React from 'react';
import { ShowLayout } from 'components/details';
import { CardTitle } from 'components/common';
import { Delivery } from 'icons';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  label: {
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },

  value: {
    fontSize: 16,
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
}));

const DeliverySection = (props) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography className={classes.label} component="div" variant="overline">
            Signature
          </Typography>
          <Typography data-test="signature" className={classes.value} variant="body1">
            {get(props, 'record.params.signature') ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.label} component="div" variant="overline">
            Insurance
          </Typography>
          <Typography data-test="insurance" className={classes.value} variant="body1">
            {get(props, 'record.params.insurance') ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        {!!get(props, 'record.params.insurance') && (
          <Grid item xs={4}>
            <Typography className={classes.label} component="div" variant="overline">
              Insurance value
            </Typography>
            <Typography data-test="residential" className={classes.value} variant="body1">
              {get(props, 'record.params.packageInfo[0].insuredValue')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const DeliveryCard = () => (
  <ShowLayout
    showingFields={[
      'record.params.signature',
      'record.params.insurance',
      'record.params.packageInfo[0].insuredValue',
    ]}>
    <CardTitle icon={<Delivery />}>Delivery</CardTitle>
    <DeliverySection />
  </ShowLayout>
);

export default DeliveryCard;
