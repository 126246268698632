import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Dimensions = (props) => (
  <SvgIcon {...props}>
    <path
      d="M7.734 20h-.468a1 1 0 01-.497-.132l-6.265-3.58A1 1 0 010 15.42V8.58a1 1 0 01.504-.868l6.265-3.58A1 1 0 017.266 4h.468a1 1 0 01.497.132l6.265 3.58A1 1 0 0115 8.58v6.84a1 1 0 01-.504.868l-6.265 3.58a1 1 0 01-.497.132zM14 15.381V9.536L8 13v5.845l6-3.464zM7.732 5h-.464L1.206 8.5 7.268 12h.464l6.062-3.5L7.732 5zM1 9.536v5.845l6 3.464V13L1 9.536zM19 14h1l-1.5 2-1.5-2h1v-4h-1l1.5-2 1.5 2h-1v4zM15.482 3.567l.5-.866L16.964 5l-2.482.299.5-.866-3.464-2-.5.866L10.036 1l2.482-.299-.5.866 3.464 2zm-3.464 18.866l.5.866L10.036 23l.982-2.299.5.866 3.464-2-.5-.866 2.482.299-.982 2.299-.5-.866-3.464 2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Dimensions;
