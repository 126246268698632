import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useGetOne, useVersion } from 'react-admin';

const UserName = () => {
  const version = useVersion();
  const { data: profile, loading } = useGetOne('profile', 'profile', { version });

  if (loading || !profile) return <CircularProgress size={24} />;

  return <span>{profile.email}</span>;
};

export default UserName;
