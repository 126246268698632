import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core';
import { AddOutlined, RemoveOutlined } from '@material-ui/icons';
import classNames from 'classnames';

import ToolButton from 'components/buttons/ToolButton';
import { getAmountObject, getCurrency } from '../helpers/balance';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxSizing: 'border-box',
      height: theme.spacing(11),
      borderRadius: theme.spacing(5.5),
      padding: theme.spacing(2, 4),
      fontSize: 16,
      fontWeight: 'bold',
    },

    inner: {
      display: 'flex',
      alignItems: 'center',
    },

    caption: {
      marginLeft: theme.spacing(2),
      textTransform: 'uppercase',
    },

    icon: {
      color: theme.palette.primary.light,
    },

    value: {
      marginLeft: theme.spacing(4),
      color: theme.palette.primary.main,
      fontSize: 20,
    },

    button: {
      marginLeft: theme.spacing(4),
      minHeight: 28,
    },

    balanceLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    autoRefillLine: {
      display: 'flex',
      paddingLeft: 40,
      alignItems: 'flex-end',
    },

    usdCard: {
      marginTop: 20,
    },

    autoRefillStatus: {
      marginLeft: 30,
      color: theme.palette.primary.main,
    },
  });

const CurrencyCard = ({ classes, currencyCode, balance, onChangeBalanceRequested }) => {
  const currency = getCurrency(currencyCode);
  const amount = getAmountObject(balance, currencyCode);

  const handleClick = (commandCode) => () => {
    onChangeBalanceRequested({ commandCode, currencyCode });
  };

  const isUSD = currencyCode === 'USD';
  const autoRefillStatus = balance?.find((item) => item.currency === 'USD')?.autoRefillEnabled;

  return (
    <Paper className={classes.root}>
      <Box className={classNames(classes.balanceLine, isUSD && classes.usdCard)}>
        <Box className={classes.inner}>
          <currency.icon className={classes.icon} />
          <span className={classes.caption}>{currency.caption}</span>
          <span className={classes.value}>
            {amount ? currency.format(amount.balance) : <CircularProgress />}
          </span>
        </Box>
        <Box className={classes.inner}>
          <ToolButton
            className={classes.button}
            startIcon={<AddOutlined />}
            onClick={handleClick('refill')}>
            Refill
          </ToolButton>
          <ToolButton
            className={classes.button}
            startIcon={<RemoveOutlined />}
            onClick={handleClick('charge')}>
            Charge
          </ToolButton>
        </Box>
      </Box>
      {isUSD && (
        <Box className={classes.autoRefillLine}>
          <Typography>Auto-refill:</Typography>
          <Typography className={classes.autoRefillStatus}>
            {autoRefillStatus ? 'Enabled' : 'Disabled'}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default withStyles(styles)(CurrencyCard);
