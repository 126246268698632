import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Edit = (props) => (
  <SvgIcon {...props}>
    <path
      d="M17.874 3.293l2.833 2.833a1.002 1.002 0 010 1.417l-10.331 10.33a1.002 1.002 0 01-.708.294L4.002 21A1.002 1.002 0 013 19.998l2.833-5.666c0-.265.106-.52.293-.708l10.331-10.33a1.002 1.002 0 011.417 0zm-3.292 4.703l-6.749 6.75v.058L6.47 17.53l2.726-1.363h.058l6.749-6.75-1.421-1.421zm2.583-2.582l-1.169 1.168 1.421 1.421 1.17-1.168-1.422-1.421z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Edit;
