import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  unverifiedChip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#F6ECF8',
    color: '#BB24C7',
  },

  verifiedChip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#DFFFE6',
    color: '#0CA82D',
  },
}));

const VerificationStatus = ({ label, verified = false }: { label: string; verified?: boolean }) => {
  const classes = useStyles();

  return verified ? (
    <Chip label={label} className={classes.verifiedChip} />
  ) : (
    <Chip label={label} className={classes.unverifiedChip} />
  );
};

export default VerificationStatus;
