import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Balance = (props) => (
  <SvgIcon {...props}>
    <path
      d="M19 3c1.1 0 2 .9 2 2v2.28A2 2 0 0122 9v6a2 2 0 01-1 1.72V19c0 1.1-.9 2-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2zm0 2H5v14h14v-2h-6c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h6V5zm1 4h-7v6h7V9zm-4 1.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Balance;
