import React, { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';

export const SetLossStatusActionField = forwardRef((props: any, ref: any) => {
  const { record } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSetLoss = async () => {
    try {
      const { data } = await dataProvider.post('debtorsLoss', {
        id: record.id,
      });
      refresh();
      return data;
    } catch (error: any) {
      notify(error.message);
    }
  };
  return (
    <MenuItem ref={ref} onClick={handleSetLoss}>
      Set Loss status
    </MenuItem>
  );
});
