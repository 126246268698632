import React from 'react';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { useDataProvider, useRefresh } from 'react-admin';
import { FORM_ERROR } from 'final-form';

import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { positive, required } from 'components/form/validationRules';

import TypeChooser from './TypeChooser';
import { CreditCodeType } from '../types';
import DateInputField from './DateInputField';
import TimeInputField from './TimeInputField';
import NumberInput from 'components/form/NumberInput';
import CurrencyInput from 'components/form/CurrencyInput';

const useStyles = makeStyles((theme) => ({
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  date: {
    padding: theme.spacing(3, 0, 1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actions: {
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  input: {
    '& .MuiInput-input': {
      padding: theme.spacing(1, 0),
    },
  },
}));

interface Props {
  open: boolean;
  onCancel: () => void;
}

interface FormValues {
  type: CreditCodeType;
  name: string;
  numberOfCodes: string;
  priceOfCodes: string;
  redeemLimitOfCodes: string;
  startAtDate: { date: Moment | null; time: Moment | null };
  endAtDate: { date: Moment | null; time: Moment | null };
}

const defaultValues: FormValues = {
  type: CreditCodeType.Fund,
  name: '',
  numberOfCodes: '',
  priceOfCodes: '',
  redeemLimitOfCodes: '',
  startAtDate: {
    date: null,
    time: null,
  },
  endAtDate: {
    date: null,
    time: null,
  },
};

const getDate = ({ date, time }: { date: Moment | null; time: Moment | null }) => {
  if (!date || !time) {
    return;
  }

  return `${date.format('YYYY-MM-DD')}T${time.format('HH:mm:00.000Z')}`;
};

const CreateCampaignDialog = ({ open, onCancel }: Props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleSubmitForm = async (values: FormValues) => {
    try {
      const startAtDate = getDate(values.startAtDate);
      const endAtDate = getDate(values.endAtDate);
      const today = moment();

      if (moment(startAtDate).isBefore(today)) {
        throw new Error('Start date is in the past. Please check your entries');
      }

      if (moment(endAtDate).isBefore(today)) {
        throw new Error('End date is in the past. Please check your entries');
      }

      if (moment(startAtDate).isAfter(moment(endAtDate))) {
        throw new Error('Start date is later than end date. Please check your entries');
      }

      const requestData = {
        ...values,
        startAtDate: startAtDate,
        endAtDate: endAtDate,
      };

      const response = await dataProvider.post('creditCodes', { data: requestData });

      onCancel();

      refresh();

      return response;
    } catch (error: any) {
      return { [FORM_ERROR]: error?.message };
    }
  };

  return (
    <Form<FormValues>
      initialValues={defaultValues}
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, submitting, values, submitError }) => {
        return (
          <Dialog
            open={open}
            onCancel={onCancel}
            title="Create campaign"
            onConfirm={handleSubmit}
            submitting={submitting}
            classes={{ actions: classes.actions }}
            confirmText="Create"
            error={submitError}>
            <TypeChooser name="type" />

            <Input name="name" label="Campaign name" validate={required} />

            <div className={classes.inputsContainer}>
              <NumberInput
                name="numberOfCodes"
                label="Number of codes"
                validate={positive}
                isInteger
              />

              <CurrencyInput
                name="priceOfCodes"
                label="Amount"
                validate={positive}
                classes={{ input: classes.input }}
                inputProps={{
                  InputProps: {
                    startAdornment: values.type === CreditCodeType.Fund ? <span>$</span> : '',
                  },
                }}
              />

              <NumberInput
                name="redeemLimitOfCodes"
                label="Usage limit per code"
                validate={positive}
                isInteger
              />
            </div>

            <div className={classes.date}>
              Start date
              <div className={classes.datePickerContainer}>
                <DateInputField
                  name="startAtDate.date"
                  validate={required}
                  label="Select date"
                  pickerProps={{
                    disablePast: true,
                    maxDate: values.endAtDate.date?.format('YYYY-MM-DD'),
                  }}
                />
                <TimeInputField name="startAtDate.time" validate={required} label="Select time" />
              </div>
            </div>

            <div className={classes.date}>
              End date
              <div className={classes.datePickerContainer}>
                <DateInputField
                  name="endAtDate.date"
                  validate={required}
                  label="Select date"
                  pickerProps={{
                    disablePast: true,
                  }}
                />
                <TimeInputField name="endAtDate.time" validate={required} label="Select time" />
              </div>
            </div>
          </Dialog>
        );
      }}
    />
  );
};

export default CreateCampaignDialog;
