import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles, Button } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';

const styles = (theme) =>
  createStyles({
    button: {
      height: theme.spacing(4),
      borderColor: theme.palette.primary.light,
      transition: theme.transitions.create(['background-color', 'box-shadow', 'border', 'color']),
      textTransform: 'none',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },

    unpressed: {
      color: theme.palette.secondary.contrastText,
    },

    error: {
      borderColor: theme.palette.error.main,
    },

    icon: {
      fontSize: 14,
      marginRight: theme.spacing(1),
    },
  });

const ToggleButton = ({ classes, toggled, className, children, error, ...props }) => (
  <Button
    {...props}
    variant="outlined"
    color="primary"
    className={classNames(
      className,
      classes.button,
      !toggled && classes.unpressed,
      error && classes.error
    )}>
    {toggled && <CheckOutlined className={classes.icon} />}
    {children}
  </Button>
);

export default withStyles(styles)(ToggleButton);
