import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Button, Card, CircularProgress, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    padding: theme.spacing(3),
    width: '50%',
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
  },
  primaryButton: {
    textTransform: 'none',
  },
  secondaryButton: {
    textTransform: 'none',
    color: theme.palette.secondary.contrastText,
  },
}));

const ClaimPhotos = () => {
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const [loading, setLoading] = useState<boolean>(false);

  const downloadPhotos = async () => {
    try {
      setLoading(true);

      const { data } = await dataProvider.getNest('claimPhotos', {
        id,
        headers: { Accept: 'application/octet-stream' },
      });

      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `claim_${id}.zip`);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      notify(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClaimsRedirect = () => {
    redirect('list', '/claims');
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Typography variant="h4" align="center">
          Claim photos
        </Typography>

        <Button
          color="primary"
          size="large"
          variant="contained"
          className={classes.primaryButton}
          onClick={downloadPhotos}
          disabled={loading}
          fullWidth>
          {loading ? <CircularProgress size={20} /> : 'Download file'}
        </Button>

        <Button
          size="large"
          variant="outlined"
          className={classes.secondaryButton}
          onClick={handleClaimsRedirect}
          fullWidth>
          Back to claims
        </Button>
      </Card>
    </div>
  );
};

export default ClaimPhotos;
