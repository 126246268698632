import WarehouseField from 'components/fields/WarehouseField';
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { ListAltOutlined } from '@material-ui/icons';
import { DateField } from 'react-admin';
import { get } from 'lodash';
import {
  LabeledPriceField,
  StatusField,
  PriceField,
  ProviderField,
  LabeledUrlField,
} from 'components/fields';
import { ShowLayout } from 'components/details';
import { CardTitle } from 'components/common';
import ReturnLabelChip from 'components/common/ReturnLabelChip';
import Divider from './Divider';

const useStyles = makeStyles((theme) => ({
  major: {
    fontSize: 20,
    lineHeight: '32px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    overflowWrap: 'break-word',
  },

  date: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',

    '&:not(:first-child)': {
      marginTop: theme.spacing(4),
      paddingTop: theme.spacing(4),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },

  itemImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    marginRight: theme.spacing(4),
    width: theme.spacing(25),
    height: theme.spacing(25),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(2),
  },

  itemImage: {
    maxWidth: theme.spacing(23),
    maxHeight: theme.spacing(23),
  },

  itemProps: {
    flexGrow: 1,
    position: 'relative',
    maxWidth: 'calc(100% - 232px)',
  },

  description: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  skuWrapper: {
    margin: theme.spacing(4, 0),
    maxWidth: '100%',
  },

  sku: {
    fontWeight: 'bold',
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.primary,
    maxWidth: '100%',
  },

  quantity: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
  },

  quantityHead: {
    marginRight: theme.spacing(1),
  },

  total: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: 'bold',
  },

  finance: {
    '& > :not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },

  status: {
    marginTop: theme.spacing(1.5),
  },

  tracking: {
    margin: theme.spacing(-3, 0),
  },

  copyTracking: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.secondary.contrastText,
  },

  warehouse: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },

  arrayField: {
    width: '100%',
  },
}));

const ShipmentSection = (props) => {
  const classes = useStyles();
  const markedAsShipped = get(get(props.record, 'account'), 'number') === 'Manual';
  const isReturn = get(props.record, 'type').toLowerCase() === 'return';

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item container direction="column" xs={6}>
        <WarehouseField
          {...props}
          source={isReturn ? 'params.to' : 'params.from'}
          className={classes.warehouse}
        />
        <PriceField {...props} source="cost" className={classes.major} />
        <DateField
          data-test="shipmentDate"
          className={classes.date}
          {...props}
          source="params.shipmentDate"
          options={{
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }}
        />
      </Grid>
      <Grid container item xs={6} direction="column" alignItems="flex-end">
        <ProviderField
          smallProviderLogo
          reverse
          record={props.record}
          imageSource="deliveryProvider.logoUrl"
          source="deliveryProvider.code"
          secondarySource="params.serviceName"
          isSummary
        />
        <ReturnLabelChip record={props.record} source="type" />
        <StatusField
          className={classes.status}
          markedAsShipped={markedAsShipped}
          record={props.record.status}
        />
      </Grid>
    </Grid>
  );
};

const TrackingSection = (props) => {
  const classes = useStyles();
  if (!props.record.trackNumber) return null;

  return (
    <>
      <Divider />
      <Box className={classes.tracking}>
        <LabeledUrlField
          enableCopy
          record={props.record}
          source="trackLink"
          labelSource="trackNumber"
        />
      </Box>
    </>
  );
};

const FinanceSection = (props) => {
  const classes = useStyles();
  const { record } = props;
  if (!record.order) {
    return null;
  }

  const itemsSum = (record?.order.items ?? []).reduce(
    (acc, item) => acc + (item.price ?? 0) * (item.quantity ?? 1),
    0
  );
  const discount =
    (record.order.shippingPaid ?? 0) +
    (record.order.tax ?? 0) +
    itemsSum -
    (record.order.total ?? 0);

  return (
    <>
      <Divider />
      <Box className={classes.finance}>
        <LabeledPriceField {...props} label="Shipping paid" source="order.shippingPaid" />
        <LabeledPriceField {...props} label="Tax" source="order.tax" />
        <LabeledPriceField
          {...props}
          label={discount >= 0 ? 'Discount' : 'Increase'}
          fixedValue={Math.abs(discount) * (discount > 0 ? -1 : 1)}
        />
        <LabeledPriceField
          {...props}
          classes={{ price: classes.total }}
          label="Total"
          source="order.total"
        />
      </Box>
    </>
  );
};

const SummaryCard = () => (
  <ShowLayout>
    <CardTitle icon={<ListAltOutlined />}>Summary</CardTitle>
    <ShipmentSection />
    <TrackingSection />
    <FinanceSection />
  </ShowLayout>
);

export default SummaryCard;
