import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Weight = (props) => (
  <SvgIcon {...props}>
    <path
      d="M14 9h-.388a4 4 0 013.945 3.342l1.249 7.494A1 1 0 0117.82 21H6.18a1 1 0 01-.986-1.164l1.249-7.494A4 4 0 0110.389 9H10V7H8.236A3.618 3.618 0 015 5l-.276-.553A1 1 0 015.618 3h12.764a1 1 0 01.895 1.447L19 5a3.618 3.618 0 01-3.236 2H14zm-1 0V7h-2v2zm.612 1h-3.223a3 3 0 00-2.96 2.507L6.181 20H17.82l-1.25-7.493A3 3 0 0013.613 10zM8.236 6h7.528c.992 0 1.898-.56 2.342-1.447L18.382 4H5.618l.277.553A2.618 2.618 0 008.236 6zm3.264 7.5a.5.5 0 011 0v.634a1 1 0 11-1 0zM12 19a4 4 0 110-8 4 4 0 010 8zm0-1a3 3 0 100-6 3 3 0 000 6z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Weight;
