import { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { uniqueId } from 'lodash';

const useAdditionalDocument = (type: string, id: string, name: string, code: string) => {
  const [openingDocument, setOpeningDocument] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const openDocument = async () => {
    try {
      if (openingDocument) {
        return;
      }

      setOpeningDocument(true);

      const {
        data: { url, noticeHtml },
      } = await dataProvider.getNest('additionalDocument', { type, id, code });

      return { name, url, id: uniqueId('doc_'), noticeHtml };
    } catch (error: any) {
      notify(error?.message, 'error');
    } finally {
      setOpeningDocument(false);
    }
  };

  return { openingDocument, openDocument };
};

export default useAdditionalDocument;
