import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core';
import { Ascending, Descending } from 'icons';

const styles = (theme) =>
  createStyles({
    root: {
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },

    active: {
      color: theme.palette.primary.main,
    },

    icon: {
      verticalAlign: 'bottom',
      marginLeft: theme.spacing(0.5),
    },
  });

const SortLabel = ({ classes, currentSort, sortKey, onSort, children }) => {
  const isActive = currentSort.key === sortKey;

  const handleClick = () => {
    onSort({
      key: sortKey,
      dir: isActive && currentSort.dir === 'asc' ? 'desc' : 'asc',
    });
  };

  let sortIcon;

  if (isActive) {
    switch (currentSort.dir) {
      case 'asc':
        sortIcon = <Ascending className={classes.icon} />;
        break;

      case 'desc':
        sortIcon = <Descending className={classes.icon} />;
        break;
    }
  }

  return (
    <span className={classNames(classes.root, isActive && classes.active)} onClick={handleClick}>
      {children}
      {sortIcon}
    </span>
  );
};

export default withStyles(styles)(SortLabel);
