import React, { useState } from 'react';
import { BusinessCenterOutlined, DescriptionOutlined } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';
import { ShowLayout } from 'components/details';
import { CardTitle } from 'components/common';
import useAdditionalDocument from 'hooks/useAdditionalDocuments';
import Lightbox, { LightboxDocument } from 'components/layout/dialogs/Lightbox';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    height: theme.spacing(3),
  },

  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.light,
  },

  disabledLink: {
    color: theme.palette.text.disabled,
    textDecoration: 'none !important',
    cursor: 'default',
  },
}));

const DocumentLink = ({ id, name, code }) => {
  const classes = useStyles();
  const { openingDocument, openDocument } = useAdditionalDocument('shipments', id, name, code);

  const [docs, setDocs] = useState<LightboxDocument[] | null>(null);

  const handleClick = async (event) => {
    event.preventDefault();

    const doc = await openDocument();

    if (!doc) return;

    setDocs([doc]);
  };

  const handleClose = () => {
    setDocs(null);
  };

  return (
    <>
      <Box className={classes.item}>
        <span className={classes.icon}>
          {openingDocument ? <CircularProgress size={16} /> : <DescriptionOutlined />}
        </span>
        <Link
          data-test="document-link"
          className={openingDocument ? classes.disabledLink : ''}
          href="#"
          onClick={handleClick}>
          {name}
        </Link>
      </Box>

      {Boolean(docs?.length) && <Lightbox docs={docs} onClose={handleClose} />}
    </>
  );
};

const AdditionalDocumentsSection = (props) => {
  const { id, additionalDocuments } = props.record;

  return (
    <Box>
      {additionalDocuments.map((doc) => (
        <DocumentLink key={doc.code} id={id} name={doc.name} code={doc.code} />
      ))}
    </Box>
  );
};

const AdditionalDocumentsCard = () => (
  <ShowLayout when={(record) => record.additionalDocuments}>
    <CardTitle icon={<BusinessCenterOutlined />}>Additional Documents</CardTitle>
    <AdditionalDocumentsSection />
  </ShowLayout>
);

export default AdditionalDocumentsCard;
