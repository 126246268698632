import React from 'react';
import { get } from 'lodash';
import RefundDetails from 'components/common/RefundDetails';

const ADJUSTMENTS = ['DEPOSIT_ADJUSTMENT', 'PURCHASE_ADJUSTMENT'];

const DetailsField = (props) => {
  const { record, source } = props;
  const description = get(record, source);

  const isAdjustment = ADJUSTMENTS.includes(props.record.type);

  return (
    <>
      <span>{description}</span>
      {isAdjustment && <RefundDetails title="Adjustment details" record={record} />}
    </>
  );
};

export default DetailsField;
