import React, { useRef, useState } from 'react';
import {
  ClickAwayListener,
  createStyles,
  Grow,
  MenuList,
  Paper,
  Popper,
  withStyles,
} from '@material-ui/core';
import { ArrowDropDownOutlined } from '@material-ui/icons';
import AppBarTab from './AppBarTab';

const styles = (theme) =>
  createStyles({
    dropdownIcon: {
      color: theme.palette.primary.light,
      margin: theme.spacing(0, -0.75, 0, 0.75),
    },

    menu: {
      color: theme.palette.text.secondary,
    },
  });

const DropdownTabContent = ({ classes, children, items, ...rest }, ref) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(ref);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (prevHandler?) => (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    prevHandler && prevHandler();
  };

  return (
    <>
      <button {...rest} ref={anchorRef} onClick={handleToggle}>
        {children}
        <ArrowDropDownOutlined className={classes.dropdownIcon} />
      </button>
      <Popper open={open} anchorEl={anchorRef.current} transition style={{ zIndex: 1500 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper elevation={8}>
              <ClickAwayListener onClickAway={handleClose()}>
                <MenuList className={classes.menu}>
                  {React.Children.map(items, (item) =>
                    React.cloneElement(item, { onClick: handleClose(item.props.onClick) })
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const EnhancedDropdownTabContent = withStyles(styles)(React.forwardRef(DropdownTabContent));

const DropdownTab = ({ children, ...rest }) => (
  <AppBarTab
    {...rest}
    textColor="primary"
    component={EnhancedDropdownTabContent}
    items={children}
  />
);

export default DropdownTab;
