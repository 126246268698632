import React, { memo } from 'react';
import { TextField, ReferenceArrayInput, ReferenceField } from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { List, Datagrid, Filter, Actions } from 'components/list';
import { PriceField, ActionField, StatusField } from 'components/fields';
import { SetLossStatusActionField } from './components/SetLossStatus';
import { ExportAccountDataActionField } from './components/ExportAccountData';
import ExportDebtorsButton from './components/ExportDebtorsButton';
import DebtorsDateField from './components/DebtorsDateField';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';

const useDebtorsStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
  },
  gap: {
    flexGrow: 1,
    minWidth: theme.spacing(2),
  },
  button: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 180,
    alignSelf: 'flex-start',
    marginRight: 8,
    boxShadow: 'none',
  },
  disableHiglighting: {
    '&:hover>button': {
      backgroundColor: '#FFFFFF',
    },
  },
  dialogRoot: {
    maxWidth: '100%',
  },
  form: {
    width: '50%',
    '& .filter-field': {
      width: '50%',
    },
  },
}));

const DebtorsFilters = ({ ...props }) => {
  const classes = useDebtorsStyles();

  return (
    <Filter withSearch {...props} searchKey="search" classes={classes}>
      <ReferenceArrayInput
        icon={CheckCircleOutlineIcon}
        basePath={props.basePath}
        label="Status"
        source="status"
        reference="debtorsStatuses"
        allowEmpty>
        <ChipsArrayInput optionValue="code" optionLabel="name" />
      </ReferenceArrayInput>
    </Filter>
  );
};

const DebtorsActions = (props) => {
  const classes = useDebtorsStyles();
  return (
    <Actions {...props}>
      <Box className={classes.button}>
        <ExportDebtorsButton />
      </Box>
    </Actions>
  );
};

const DebtorsList = (props) => {
  return (
    <List {...props} filters={<DebtorsFilters />} actions={<DebtorsActions />}>
      <Datagrid>
        <TextField source="name" label="Customer name" sortable={false} />
        <TextField source="email" label="Email" />
        <DebtorsDateField source="latestShipmentTime" label="Last shipment" />
        <DebtorsDateField source="latestTransitionTime" label="Last notification" />
        <PriceField source="balanceAtLastTransition" label="Balance" />
        <ReferenceField
          label="Status"
          source="currentStage"
          reference="debtorsStatuses"
          link={false}>
          <StatusField />
        </ReferenceField>
        <ActionField>
          <SetLossStatusActionField />
          <ExportAccountDataActionField />
        </ActionField>
      </Datagrid>
    </List>
  );
};

export default memo(DebtorsList);
