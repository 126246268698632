import { Children, cloneElement } from 'react';

const ListField = ({ data = {}, ids = [], children, ...rest }) =>
  cloneElement(Children.only(children), {
    data,
    ids,
    total: ids.length,
    ...rest,
  });

export default ListField;
