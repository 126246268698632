import { GET_LIST, GET_MANY } from 'react-admin';

const convertToHumanReadableFormat = (value: string) => {
  const str = value.split('_').join(' ');
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

const getFraudTypes = ({ response, url, options, httpClient, params, apiUrl }) => ({
  ...response,
  json: response.json.map((item, index) => ({
    id: `${item}-${index}`,
    code: item,
    name: convertToHumanReadableFormat(item),
  })),
});

export default {
  [GET_LIST]: getFraudTypes,
  [GET_MANY]: getFraudTypes,
};
