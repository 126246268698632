import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { EditFraudDetailsDialog } from './EditFraudDetailsDialog';
import addressToString from 'services/helpers/addressToString';
import { isValidJSON } from 'services/helpers/jsonUtils';

import { UserDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  managementCard: {
    padding: theme.spacing(3),
  },

  fraudDescriptionLine: {
    display: 'flex',
    padding: '8px 0',
  },

  fraudKey: {
    minWidth: '150px',
    color: theme.palette.secondary.contrastText,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
  },

  editButton: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  user: UserDetails;
}

export const FraudDetailsSection = ({ user }: Props) => {
  const classes = useStyles();

  const { fraudDescription } = user;

  const [open, setOpen] = useState<boolean>(false);

  const parseAddress = (address: string) => {
    return isValidJSON(address) ? addressToString(JSON.parse(address)) : address;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!fraudDescription) {
    return null;
  }

  return (
    <Card className={classes.managementCard}>
      {fraudDescription.orderId && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Order</Typography>
          <Typography>{fraudDescription.orderId}</Typography>
        </div>
      )}
      {fraudDescription.fromAddress && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>From address</Typography>
          <Typography>{parseAddress(fraudDescription.fromAddress)}</Typography>
        </div>
      )}
      {fraudDescription.toAddress && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>To address</Typography>
          <Typography>{parseAddress(fraudDescription.toAddress)}</Typography>
        </div>
      )}
      {user.fraudReason && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Block type</Typography>
          <Typography>{user.fraudReason}</Typography>
        </div>
      )}
      {fraudDescription.reason && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Block reason</Typography>
          <Typography>{fraudDescription.reason}</Typography>
        </div>
      )}
      {fraudDescription.dimensions && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Dimensions</Typography>
          <Typography>{fraudDescription.dimensions}</Typography>
        </div>
      )}
      {fraudDescription.badDimensions && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Bad dimensions pattern</Typography>
          <Typography>{fraudDescription.badDimensions}</Typography>
        </div>
      )}
      {fraudDescription.weight && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Weight</Typography>
          <Typography>{fraudDescription.weight}</Typography>
        </div>
      )}
      {user.fraudDetectedAt && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Fraudulent flag set</Typography>
          <Typography>{moment(user.fraudDetectedAt).format('D MMM YYYY, h:m a')}</Typography>
        </div>
      )}
      {user.fraudBlockedAt && (
        <div className={classes.fraudDescriptionLine}>
          <Typography className={classes.fraudKey}>Suspended</Typography>
          <Typography>{moment(user.fraudBlockedAt).format('D MMM YYYY, h:m a')}</Typography>
        </div>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        className={classes.editButton}>
        Edit Fraud Details
      </Button>

      <EditFraudDetailsDialog open={open} onCancel={handleClose} user={user} />
    </Card>
  );
};
