import { Chip, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  activeChip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#DFFFE6',
    color: '#0CA82D',
  },
  suspendedChip: {
    height: 18,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#F6ECF8',
    color: '#BB24C7',
  },
}));

interface Props {
  record?: Record<string, any>;
}

const UserStatusField = ({ record }: Props) => {
  const classes = useStyles();

  if (!record?.id) return null;

  if (!record.cognitoEnabled) {
    return <Chip label="Suspended" className={classes.suspendedChip} />;
  }

  return <Chip label="Active" className={classes.activeChip} />;
};

export default UserStatusField;
