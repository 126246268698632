import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import { createStyles, withStyles } from '@material-ui/core';
import { LogoImage, TwoLinesLabel } from 'components/common';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },

    logo: {},

    primary: {},

    secondary: {},

    reverse: {
      flexDirection: 'row-reverse',

      '& $logo': {
        marginLeft: theme.spacing(2),
        marginRight: 0,
      },
    },
  });

const ProviderField = (props) => {
  const { classes, record, imageSource, source, secondarySource, smallProviderLogo, reverse } =
    props;

  const image = get(record, imageSource);
  const primary = get(record, source);
  const secondary = get(record, secondarySource);

  return (
    <Box className={classNames(classes.root, reverse && classes.reverse)}>
      <LogoImage className={classes.logo} src={image} small={smallProviderLogo} />
      <TwoLinesLabel
        classes={{ primary: classes.primary, secondary: classes.secondary }}
        primary={primary}
        secondary={secondary}
        small={smallProviderLogo}
        align={reverse ? 'right' : 'left'}
      />
    </Box>
  );
};

export default withStyles(styles)(ProviderField);
