import React from 'react';
import { get } from 'lodash';
import { TwoLinesLabel } from 'components/common';

const NameWithEmailField = (props) => {
  const { record, firstNameSource, lastNameSource, emailSource } = props;

  const name = `${get(record, firstNameSource, '')} ${get(record, lastNameSource, '')}`;
  const email = get(record, emailSource, '');

  return <TwoLinesLabel primary={name} secondary={email} />;
};

export default NameWithEmailField;
