import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Link } from 'react-router-dom';

const styles = (theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2),
    },

    title: {
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 'bold',
    },

    actions: {
      textAlign: 'right',
    },

    content: {
      padding: theme.spacing(0, 4, 4),
    },

    note: {
      padding: theme.spacing(2, 4, 4),
      fontSize: 14,
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
  });

const FormCard = ({ classes, basePath, title, note, actions, children, ...props }) => (
  <Grid container justify="center">
    <Grid item xs={6}>
      <Paper>
        <Grid container justify="space-between" alignItems="center" className={classes.header}>
          <Grid item xs={2}>
            <IconButton component={Link} to={basePath}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8} className={classes.title}>
            {title}
          </Grid>
          <Grid item xs={2} className={classes.actions}>
            {actions}
          </Grid>
        </Grid>
        <Box className={classes.content}>{children}</Box>
        {note && <Box className={classes.note}>{note}</Box>}
      </Paper>
    </Grid>
  </Grid>
);

export default withStyles(styles)(FormCard);
