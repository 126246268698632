import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    primary: {},

    secondary: {},

    sizeSmall: {
      height: theme.spacing(5.5),
    },

    sizeMedium: {
      height: theme.spacing(6),
    },

    sizeBig: {
      height: theme.spacing(7),

      '& $primary': {
        fontSize: 20,
        lineHeight: '32px',
        fontWeight: 'bold',
      },
    },
  });

const TwoLinesLabel = ({ classes, primary, secondary, ...rest }) => {
  let sizeClass = classes.sizeMedium;

  switch (true) {
    case rest.autoSize:
      sizeClass = '';
      break;

    case rest.small:
      sizeClass = classes.sizeSmall;
      break;

    case rest.big:
      sizeClass = classes.sizeBig;
      break;
  }

  return (
    <Box className={classNames(classes.root, sizeClass)}>
      <Typography
        className={classes.primary}
        component={Box}
        variant="body1"
        color="textPrimary"
        align={rest.align}>
        {primary}
      </Typography>
      <Typography
        className={classes.secondary}
        component={Box}
        variant="body2"
        color="textSecondary"
        align={rest.align}>
        {secondary}
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(TwoLinesLabel);
