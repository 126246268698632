import React from 'react';
import classNames from 'classnames';
import { createStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import ToggleButton from './ToggleButton';

const styles = (theme) =>
  createStyles({
    root: {
      flexShrink: 0,
    },

    button: {
      '&:first-child': {},

      '&:not(:first-child)': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '&:not(:last-child)': {
        borderRight: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  });

const ToggleOptions = ({
  className,
  classes,
  options,
  error,
  multi,
  onChange,
  ...props
}: {
  className?: string;
  classes: any;
  value: any;
  options: Array<{ label: string; value: any }>;
  error?: string;
  multi?: boolean;
  onChange: (value: any) => void;
}) => {
  let value;
  let isToggled;
  let handleChange;

  if (multi) {
    value = props.value || [];
    isToggled = (option) => value.includes(option.value);
    handleChange = (option) => () => {
      if (isToggled(option)) {
        onChange(value.filter((v) => v !== option.value));
      } else {
        onChange([...value, option.value]);
      }
    };
  } else {
    value = props.value;
    isToggled = (option) => value === option.value;
    handleChange = (option) => () => {
      onChange(option.value);
    };
  }

  return (
    <Box className={classNames(classes.root, className)}>
      {options.map((option) => (
        <ToggleButton
          key={option.label}
          className={classes.button}
          toggled={isToggled(option)}
          error={error}
          onClick={handleChange(option)}>
          {option.label}
        </ToggleButton>
      ))}
    </Box>
  );
};

export default withStyles(styles)(ToggleOptions);
