import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { WizardSteps } from './index';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core';
import SubmitButton from '../buttons/SubmitButton';

const styles = (theme) =>
  createStyles({
    footer: {
      marginTop: theme.spacing(4),
    },

    secondaryButton: {
      backgroundColor: theme.palette.secondary.light,

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  });

const Wizard = ({ classes, initialValues = {}, steps, onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const isLastStep = currentStep === steps.length - 1;

  const goToPreviousStep = () => setCurrentStep((index) => index - 1);
  const goToNextStep = () => setCurrentStep((index) => index + 1);

  const handleSubmitForm = (values) => {
    if (!isLastStep) {
      goToNextStep();
      return;
    }

    return onSubmit(values).catch((err) => ({ [FORM_ERROR]: err.message }));
  };

  const Page = steps[currentStep].component;

  return (
    <Box>
      <WizardSteps steps={steps} currentStep={currentStep} onSelect={setCurrentStep} />

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, submitting, submitError, values }) => (
          <form onSubmit={handleSubmit}>
            <Page values={values} />

            {submitError && (
              <FormLabel error component="div">
                {submitError}
              </FormLabel>
            )}
            <Grid container spacing={2} className={classes.footer}>
              <Grid item xs={6}>
                <Button
                  className={classes.secondaryButton}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={currentStep === 0}
                  onClick={goToPreviousStep}>
                  Previous
                </Button>
              </Grid>
              <Grid item xs={6}>
                <SubmitButton
                  fullWidth
                  color="primary"
                  size="large"
                  submitting={submitting}
                  label={isLastStep ? 'Submit' : 'Next'}
                />
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

export default withStyles(styles)(Wizard);
