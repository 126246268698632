import React, { Children, cloneElement } from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, withStyles } from '@material-ui/core';

import { GroupTitle } from 'components/common/Group';

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(-3),
      marginBottom: theme.spacing(4),
    },

    gap: {
      flexGrow: 1,
    },
  });

const CardTitle = ({ classes, basePath, record, resource, actions, children, ...props }: any) => (
  <GroupTitle className={classes.root} {...props}>
    {children}
    <Box className={classes.gap} />
    {actions &&
      Children.map(actions, (action) =>
        cloneElement(action, {
          basePath,
          record,
          resource,
          ...action.props,
        })
      )}
  </GroupTitle>
);

export default withStyles(styles)(CardTitle);
