import React from 'react';
import { createStyles, withStyles, Divider as MUIDivider } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
    },
  });

const Divider = ({ classes }) => <MUIDivider classes={classes} variant="middle" />;

export default withStyles(styles)(Divider);
