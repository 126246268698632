import React from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
  },
}));

const UserField = (props) => {
  const { record, labelSource, idSource } = props;
  const classes = useStyles();

  const label = get(record, labelSource);
  const id = get(record, idSource);

  return (
    <Link className={classes.link} href={`/users/${id}/show`} target="_blank" underline="hover">
      {label}
    </Link>
  );
};

export default UserField;
