import React, { useState } from 'react';
import { TextField, useRedirect, ReferenceArrayInput } from 'react-admin';

import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined';
import BlockIcon from '@material-ui/icons/Block';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import SharedWarehousesList from './components/SharedWarehousesList';
import { Datagrid, List, Filter } from 'components/list';
import { ActionField, DateField } from 'components/fields';
import EnableUserAction from './components/EnableUserAction';
import EditFraudDetailsDialogAction from './components/EditFraudDetailsDialogAction';
import BypassFraudAction from './components/BypassFraudAction';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';
import NameWithEmailField from './components/NameWithEmailField';
import GlobalResetPasswordButton from './components/GlobalResetPasswordButton';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: 700,
  },

  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: theme.spacing(3),
  },

  form: {
    width: '50%',
    '& .filter-field': {
      width: '100%',
    },
  },
}));

enum TabValues {
  FromAddress = 'FromAddress',
  Phone = 'Phone',
}

const States = [
  {
    label: 'Verified',
    code: 'VERIFIED',
  },
  {
    label: 'Not verified',
    code: 'NOT_VERIFIED',
  },
];

const BlockedUsersFilter = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.filterContainer}>
      <Typography className={classes.title}>Recently blocked</Typography>
      <Filter withSearch {...props} searchKey="email" classes={classes} showChips={false}>
        <ChipsArrayInput
          choices={States}
          optionValue="code"
          optionLabel="label"
          source="active"
          showSelectAll={false}
          label="Phone verification status"
          icon={VerifiedUserOutlined}
        />

        <ReferenceArrayInput
          icon={BlockIcon}
          basePath={props.basePath}
          label="Suspend reason"
          source="fraud.reason"
          reference="fraudTypes"
          allowEmpty>
          <ChipsArrayInput optionValue="code" optionLabel="name" />
        </ReferenceArrayInput>
      </Filter>
    </Box>
  );
};

const FraudUsersList = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const [type, setType] = useState<TabValues>(TabValues.FromAddress);

  const handleChangeTab = (event, value) => {
    setType(value);
  };

  return (
    <>
      <Box className={classes.filterContainer}>
        <Typography className={classes.title}>Shared</Typography>
        <GlobalResetPasswordButton />
      </Box>
      <Tabs value={type} indicatorColor="primary" textColor="primary" onChange={handleChangeTab}>
        <Tab value={TabValues.FromAddress} label="From address" />
      </Tabs>
      <SharedWarehousesList {...props} />

      <List
        {...props}
        resource="fraudDashboard"
        filter={{ blocked: true }}
        sort={{ field: 'fraudBlockedAt', order: 'DESC' }}
        filters={<BlockedUsersFilter />}>
        <Datagrid
          rowClick={(id) => {
            redirect('show', '/users', id);
          }}>
          <TextField source="id" name="id" />

          <NameWithEmailField
            label="Name"
            firstNameSource="firstName"
            lastNameSource="lastName"
            emailSource="email"
          />

          <TextField source="phone" label="Phone" />

          <DateField source="createdAt" label="Registered" />

          <DateField source="fraudDetectedAt" label="Fraud flag set" />

          <TextField source="fraudReason" label="Suspension reason" />

          <TextField source="note" label="Notes" />

          <ActionField>
            <EnableUserAction />
            <BypassFraudAction />
            <EditFraudDetailsDialogAction />
          </ActionField>
        </Datagrid>
      </List>
    </>
  );
};

export default FraudUsersList;
