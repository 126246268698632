import { Dialog } from 'components/common';
import React, { cloneElement } from 'react';
import { Field, Form } from 'react-final-form';
import { set } from 'lodash';
import { withStyles, createStyles, Button } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    filterForm: {
      display: 'flex',
      flexDirection: 'column',
    },
  });

const FilterForm = (props) => {
  const {
    filterValues: initialValues,
    filters,
    setFilters,
    classes,
    basePath,
    record,
    resource,
    formProps,
    open,
    handleClose,
  } = props;
  const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues({
    initialValues,
    filters,
  });

  return (
    <Form
      onSubmit={(values) => {
        setFilters(values);
        handleClose();
      }}
      initialValues={mergedInitialValuesWithDefaultValues}
      render={({ handleSubmit, form: { reset } }) => (
        <Dialog
          open={open}
          title={
            <>
              Filters
              <Button
                color="primary"
                onClick={() => {
                  setFilters({});
                  reset();
                }}>
                Reset
              </Button>
            </>
          }
          confirmText="Apply"
          onCancel={handleClose}
          onConfirm={handleSubmit}>
          <form onSubmit={handleSubmit} className={classes.filterForm} {...formProps}>
            {React.Children.map(filters, (filter) => (
              <Field
                key={filter.props.source}
                name={filter.props.source}
                label={filter.props.label}
                render={(inputProps) =>
                  cloneElement(filter, { ...inputProps, basePath, record, resource })
                }
              />
            ))}
          </form>
        </Dialog>
      )}
    />
  );
};

const mergeInitialValuesWithDefaultValues = ({ initialValues, filters }) => ({
  ...filters
    .filter((filterElement) => filterElement.props.alwaysOn && filterElement.props.defaultValue)
    .reduce(
      (acc, filterElement) =>
        set({ ...acc }, filterElement.props.source, filterElement.props.defaultValue),
      {}
    ),
  ...initialValues,
});

export default withStyles(styles)(FilterForm);
