import React from 'react';
import { TextField, DateField, ReferenceArrayInput } from 'react-admin';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { Datagrid, List, Filter } from 'components/list';
import ChipsArrayInput from 'components/inputs/ChipsArrayInput';

const ActivityFilters = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput
      icon={CheckCircleOutlineIcon}
      basePath={props.basePath}
      label="Activity type"
      source="actions"
      reference="userActivityTypes"
      allowEmpty>
      <ChipsArrayInput optionValue="code" optionLabel="name" />
    </ReferenceArrayInput>
  </Filter>
);

const ActivityTab = (props) => (
  <List
    {...props}
    resource="userActivity"
    basePath="/users"
    filter={{ tenant: props.id }}
    filters={<ActivityFilters />}>
    <Datagrid>
      <TextField source="description" label="Action" sortable={false} />
      <TextField source="adminUsername" label="Admin" sortable={false} />
      <DateField
        source="createdAt"
        label="Date"
        options={{
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }}
        sortable={false}
      />
    </Datagrid>
  </List>
);

export default ActivityTab;
