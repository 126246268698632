import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RedirectActionButton from './RedirectActionButton';

const styles = (theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      marginLeft: theme.spacing(-2),
    },
  });

const BackActionButton = ({
  classes,
  to = 'list',
  basePath = '',
  id,
  data,
  ...rest
}: {
  classes: any;
  to?: string;
  basePath?: string;
  id?: string | number;
  data?: any;
} & any) => (
  <RedirectActionButton
    classes={classes}
    to={to}
    basePath={basePath}
    id={id}
    data={data}
    color="default"
    variant="text"
    {...rest}>
    <KeyboardArrowLeftIcon />
  </RedirectActionButton>
);

export default withStyles(styles)(BackActionButton);
