import Auth from '@aws-amplify/auth';
import { Button, CircularProgress, FormLabel, Link, Typography, Grid } from '@material-ui/core';
import { ConfirmSignUp as AmplifyConfirmSignUp } from 'aws-amplify-react';
import { Input } from 'components/form';
import { required } from 'components/form/validationRules';
import AuthLayout, { useStyles } from 'components/layout/AuthLayout';
import React, { useState } from 'react';
import { Form } from 'react-final-form';

const ConfirmSignUpView = ({ onSubmit, onResend, initialValues, onSignIn }) => {
  const classes = useStyles();
  const [codeSending, setCodeSending] = useState(false);

  const handleSubmitForm = (values) => {
    return onSubmit({
      ...values,
      code: values.code.trim(),
    });
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (!codeSending) {
      setCodeSending(true);
      onResend().finally(() => setCodeSending(false));
    }
  };

  return (
    <AuthLayout>
      <Typography variant="h6" align="center" gutterBottom>
        Confirm Sign Up
      </Typography>
      <Form
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Input name="username" label="Username" fullWidth validate={required} disabled />
            <Input name="code" label="Code" fullWidth validate={required} />
            <Grid item container alignItems="center" className={classes.signUp}>
              <Typography align="left">
                Lost your code?&nbsp;
                <Link href="" onClick={handleReset}>
                  Resend Code
                </Link>
                &nbsp;
              </Typography>
              {codeSending && <CircularProgress size={15} />}
            </Grid>
            {submitError && <FormLabel error>{submitError}</FormLabel>}
            <Button
              className={classes.signInButton}
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              type="submit"
              disabled={submitting}>
              CONFIRM
            </Button>
            <Typography align="center" className={classes.signUp}>
              <Link href="" onClick={onSignIn}>
                Back to Sign In
              </Link>
            </Typography>
          </form>
        )}
      />
    </AuthLayout>
  );
};

class ConfirmSignUp extends AmplifyConfirmSignUp {
  onSignIn = (e) => {
    e.preventDefault();
    this.changeState('signIn');
  };

  onSubmit = ({ username, code }) => {
    if (!Auth || typeof Auth.confirmSignUp !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    return Auth.confirmSignUp(username, code)
      .then(() => this.changeState('signedUp'))
      .catch((err) => this.error(err));
  };

  onResend = () => {
    if (!Auth || typeof Auth.resendSignUp !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }

    const username = this.usernameFromAuthData();

    return Auth.resendSignUp(username).catch((err) => this.error(err));
  };

  showComponent(theme) {
    return (
      <ConfirmSignUpView
        initialValues={{ username: this.usernameFromAuthData() }}
        onSubmit={this.onSubmit}
        onResend={this.onResend}
        onSignIn={this.onSignIn}
      />
    );
  }
}

export default ConfirmSignUp;
