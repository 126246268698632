import { Datagrid, List, Actions } from 'components/list';
import { Filter, TextField } from 'react-admin';
import React from 'react';
import SearchInput from '../../components/list/SearchInput';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { ActionField } from 'components/fields';
import { DeletePhoneActionField } from './components/DeletePhoneActionField';
import AddBlockedPhoneButton from './components/AddBlockedPhoneButton';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    '& .filter-field': {
      width: '100%',
    },
    minHeight: 0,
    marginTop: 0,
  },
  container: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
  },
  gap: {
    flexGrow: 1,
    minWidth: theme.spacing(2),
  },
  button: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 180,
    alignSelf: 'flex-start',
    marginRight: 8,
    boxShadow: 'none',
  },
}));

const BlocklistFilter = (props) => {
  const classes = useStyles();

  return (
    <Filter classes={classes} {...props}>
      <SearchInput
        label="Phone"
        source="phone"
        alwaysOn
        onlySearch
        loading={props.loading}
        fullWidth
      />
    </Filter>
  );
};

const BlocklistActions = (props) => {
  const classes = useStyles();

  return (
    <Actions {...props}>
      <Box className={classes.button}>
        <AddBlockedPhoneButton />
      </Box>
    </Actions>
  );
};

const PhoneBlocklist = (props) => (
  <List {...props} filters={<BlocklistFilter />} actions={<BlocklistActions />}>
    <Datagrid>
      <TextField source="phoneNumber" />
      <ActionField>
        <DeletePhoneActionField />
      </ActionField>
    </Datagrid>
  </List>
);

export default PhoneBlocklist;
