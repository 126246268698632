import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDataProvider, useRefresh, useNotify } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

import ActionButton from 'components/buttons/ActionButton';
import { Dialog } from 'components/common';
import { Input } from 'components/form';
import { composeValidators, maxLength, required } from 'components/form/validationRules';
import { Chip, InputLabel, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sendToLabel: {
    fontSize: 12,
    color: theme.palette.secondary.contrastText,
  },
  chip: {
    backgroundColor: '#FAFAFC',
    fontSize: 16,
    marginTop: theme.spacing(0.5),
  },
  dialog: {
    minWidth: '600px',
  },
  actions: {
    '& button': {
      textTransform: 'none',
    },
  },
}));

const PushNotificationDialog = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const classes = useStyles();
  const notify = useNotify();
  const [open, setOpen] = useState<boolean>(false);

  const handleCreateNotification = async (values) => {
    try {
      const { data } = await dataProvider.post('pushNotifications', {
        data: { ...values },
      });

      refresh();
      setOpen(false);
      notify('Notification sent!');
      return data;
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <>
      {open && (
        <Form
          onSubmit={handleCreateNotification}
          render={({ handleSubmit, submitting }) => {
            return (
              <Dialog
                title="New push notification"
                onConfirm={handleSubmit}
                onCancel={() => setOpen(false)}
                open={open}
                submitting={submitting}
                confirmText="Send"
                classes={{ root: classes.dialog, actions: classes.actions }}>
                <InputLabel className={classes.sendToLabel}>Send to</InputLabel>
                <Chip label="All Rollo Ship Manager users" className={classes.chip} />
                <Input
                  name="title"
                  label="Title"
                  validate={composeValidators(required, maxLength(30))}
                  characterCount={30}
                />
                <Input
                  name="body"
                  label="Message"
                  validate={composeValidators(required, maxLength(65))}
                  characterCount={65}
                />
              </Dialog>
            );
          }}
        />
      )}
      <ActionButton onClick={() => setOpen(true)} startIcon={<AddIcon />}>
        Create new
      </ActionButton>
    </>
  );
};

export default PushNotificationDialog;
