import { Button, CircularProgress, createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { ButtonProps } from '@material-ui/core/Button';

interface SubmitButtonProps extends ButtonProps {
  submitting?: boolean;
  label?: string;
}

const styles = (theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  });

const SubmitButton = ({
  classes,
  submitting = false,
  disabled = false,
  label = 'Submit',
  ...rest
}: SubmitButtonProps) => (
  <Button
    classes={classes}
    variant="contained"
    color="primary"
    size="large"
    type="submit"
    fullWidth
    disabled={submitting || disabled}
    {...rest}>
    {!submitting && label}
    {submitting && <CircularProgress size={24} />}
  </Button>
);

export default withStyles(styles)(SubmitButton);
