import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Filters = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12.17 18H5a1 1 0 110-2h7.17a3.001 3.001 0 015.66 0H19a1 1 0 110 2h-1.17a3.001 3.001 0 01-5.66 0zM15 18a1 1 0 100-2 1 1 0 000 2zM6.17 8H5a1 1 0 110-2h1.17a3.001 3.001 0 015.66 0H19a1 1 0 110 2h-7.17a3.001 3.001 0 01-5.66 0zM9 8a1 1 0 100-2 1 1 0 000 2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SvgIcon>
);

export default Filters;
