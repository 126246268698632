import { Button } from '@material-ui/core';
import FilterForm from 'components/list/FilterForm';
import { Filters } from 'icons';
import React from 'react';

const FilterButton = ({ className, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="contained" className={className}>
        <Filters />
      </Button>
      <FilterForm
        {...props}
        open={open}
        confirmText="Apply"
        handleClose={handleClose}
        formProps={{ id: 'ordersFilterForm' }}
      />
    </>
  );
};

export default FilterButton;
