export const providers = [
  {
    label: 'UPS',
    code: 'UPS',
  },
  {
    label: 'USPS',
    code: 'USPS',
  },
];

export const reasons = [
  {
    label: 'Lost',
    code: 'LOST',
  },
  {
    label: 'Damaged',
    code: 'DAMAGED',
  },
];

export const statuses = [
  {
    label: 'Submitted',
    code: 'SUBMITTED',
    color: '#BB24C7',
    bgColor: '#F6ECF8',
  },
  {
    label: 'Processing',
    code: 'PROCESSING',
    color: '#FB9D2D',
    bgColor: '#FFF1E2',
  },
  {
    label: 'Awaiting additional info',
    code: 'AWAITING_ADDITIONAL_INFO',
    color: '#7D6213',
    bgColor: '#F0EBDF',
  },
  {
    label: 'Information received',
    code: 'INFORMATION_RECEIVED',
    color: '#69696B',
    bgColor: '#F5F5F5',
  },
  {
    label: 'Approved',
    code: 'APPROVED',
    color: '#39B5EF',
    bgColor: '#EAF8FF',
  },
  {
    label: 'Denied',
    code: 'DENIED',
    color: '#E53238',
    bgColor: '#FDF3F3',
  },
  {
    label: 'Appealed',
    code: 'APPEALED',
    color: '#BF9200',
    bgColor: '#FFF6DA',
  },
  {
    label: 'Closed',
    code: 'CLOSED',
    color: '#69696B',
    bgColor: '#F5F5F5',
  },
  {
    label: 'Pending payment',
    code: 'PENDING_PAYMENT',
    color: '#FB9D2D',
    bgColor: '#FFF1E2',
  },
  {
    label: 'Paid',
    code: 'PAID',
    color: '#0CA82D',
    bgColor: '#DFFFE6',
  },
  {
    label: 'Reopened',
    code: 'REOPENED',
    color: '#5C242F',
    bgColor: '#FBE6E9',
  },
];
