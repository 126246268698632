import React from 'react';
import { ActionToolbar } from 'components/common';
import BackActionButton from 'components/buttons/BackActionButton';
import EditActionButton from 'components/buttons/EditActionButton';

const ShowActions = (props) => {
  const { basePath, data, hasEdit, title, children } = props;

  return (
    <ActionToolbar back={<BackActionButton to="list" basePath={basePath} />} title={title}>
      {hasEdit && <EditActionButton basePath={basePath} record={data} />}
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          data,
        })
      )}
    </ActionToolbar>
  );
};

export default ShowActions;
