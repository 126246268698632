import React, { FunctionComponent } from 'react';
import { createStyles, withStyles, Grid, Typography } from '@material-ui/core';
import { FieldProps, InjectedFieldProps } from 'react-admin';
import { get } from 'lodash';

interface ValueFieldPropsInterface {
  labelVariant?: string;
  priceVariant?: string;
  formatter?: (value: any) => any;
}

const styles = (theme) =>
  createStyles({
    root: {},

    label: {
      lineHeight: '20px',
      color: theme.palette.text.secondary,
    },

    value: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 'bold',
    },
  });

const LabeledValueField: FunctionComponent<
  InjectedFieldProps & FieldProps & ValueFieldPropsInterface
> = ({
  classes,
  record,
  source,
  label,
  labelVariant = 'overline',
  valueVariant = 'body1',
  formatter = (s) => s,
}) => {
  const value = get(record, source);

  return (
    <Grid className={classes.root} container justify="space-between" alignItems="center">
      <Typography className={classes.label} variant={labelVariant}>
        {label}
      </Typography>
      <Typography className={classes.value} variant={valueVariant}>
        {formatter(value)}
      </Typography>
    </Grid>
  );
};

export default withStyles(styles)(LabeledValueField);
