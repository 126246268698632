import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  chip: {
    height: 24,
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const ClaimStatusField = (props) => {
  const { record, source } = props;

  const classes = useStyles();

  const name = get(record, `${source}.userFriendlyText`);
  const bgColor = get(record, `${source}.backgroundColor`);
  const color = get(record, `${source}.color`);

  return (
    <Chip
      label={name}
      className={classes.chip}
      style={{ backgroundColor: bgColor, color: color }}
    />
  );
};

export default ClaimStatusField;
