import React from 'react';
import { useField } from 'react-final-form';

import ToggleButton from 'components/buttons/ToggleButton';
import { makeStyles } from '@material-ui/core';
import { CreditCodeType } from '../types';

const useStyles = makeStyles(() => ({
  button: {
    '&:not(:last-child)': {
      borderRight: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    '&:not(:first-child)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    width: '50%',
  },
  container: {
    width: '100%',
  },
}));

interface Props {
  name: string;
}

const TypeChooser = ({ name }: Props) => {
  const classes = useStyles();

  const {
    input: { value, onChange },
  } = useField(name);

  const handleClickLabel = () => {
    onChange(CreditCodeType.Label);
  };

  const handleClickFund = () => {
    onChange(CreditCodeType.Fund);
  };

  return (
    <div className={classes.container}>
      <ToggleButton
        className={classes.button}
        toggled={value === CreditCodeType.Fund}
        onClick={handleClickFund}>
        Fund
      </ToggleButton>
      <ToggleButton
        className={classes.button}
        toggled={value === CreditCodeType.Label}
        onClick={handleClickLabel}>
        Label
      </ToggleButton>
    </div>
  );
};

export default TypeChooser;
